.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f0f2f5;
  margin-bottom: 35px;
  margin-left: 15px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #252733;
    padding: 25px;
    cursor: pointer;
    margin-bottom: 0;
    background-color: #f0f2f5;
  }
}
.container {
  width: 100%;
  position: relative;
  .loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .z4 {
    position: relative;
    z-index: 4;
  }

  .z3 {
    position: relative;
    z-index: 3;
  }

  .z2 {
    position: relative;
    z-index: 2;
  }

  .z1 {
    position: relative;
    z-index: 1;
  }

  .z0 {
    position: relative;
    z-index: 0;
  }
  .resetButton {
    outline: 0;
    border: none;
    background-color: #56aafe;
    color: #fcfcfc;
    padding: 10px 25px;
    border-radius: 10px;
    margin-right: 20px;
    &:disabled {
      opacity: 0.5;
    }
  }
  .selects {
    display: flex;
    width: 100%;
    .region {
      width: calc(50% - 20px);
      margin: 20px 20px 0 0;
    }
    .period {
      width: 50%;
      margin: 20px 20px 0 0;
      
    
    }

    .productIds {
      width: 50%;
      position: relative;
      margin-top: 20px;
    }
  }

  .ignoreOutOfIntervalField {
    position: relative;
    display: flex;
    margin-top: 24px;
    // width: 100%;
    align-items: center;
    .label {
      width: 360px;
    }
  }
  .datePickers {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .clearButton {
      outline: 0;
      border: 0;
      background-color: #379fff;
      color: #fcfcfc;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
    }
    input {
      width: calc(100% - 20px);
      padding: 6px;
      outline: 0;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 64px 0;
    li {
      .singleItem {
        &.light {
          color: #999999;
          cursor: default !important;
        }
        position: relative;
        border-top: 1px solid #efefef;
        padding: 22px 20px 22px 20px;
        display: grid;
        align-items: center;
        gap: 20px;
        color: #000;
        grid-template-columns: 1.5fr 1.5fr 1fr;
        &:hover {
          cursor: pointer;
          background-color: #ececec;
        }
        @media all and (max-width: 1440px) {
          padding: 10px;
        }
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          @media all and (max-width: 1440px) {
            transform: translateY(-50%) scale(0.8);
            left: 3px;
          }
        }
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          @media all and (max-width: 767px) {
            font-size: 14px;
          }
          &:first-child {
            font-weight: 600;
          }
        }
        span {
          text-align: right;
          font-size: 16px;
          font-weight: 700;
          @media all and (max-width: 767px) {
            font-size: 14px;
          }
        }
        &.selected {
          border: 1px solid #379fff;
          background-color: rgba(#56aafe, 0.2);
        }
      }
    }
    @media all and (max-width: 767px) {
      margin: 64px 0 84px;
    }
  }
}
