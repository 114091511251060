.dashboard {
    padding-top: 80px;
    padding-bottom: 80px;
    .dashboardFilters {
        margin-bottom: 26px;
    }
    .dashboardBody {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .statisticItem {
            flex: 0 0 calc(50% - 12.5px);
            border: 1px solid #f2f4f9;
            border-radius: 6px;
            padding: 20px 30px;
        }
    }
}
