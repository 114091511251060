@import "../../styles/vars";
.ProductsSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  .producstHeadeer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f0f2f5;
    margin-bottom: 35px;
    h2 {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #252733;
      padding: 25px;
      cursor: pointer;
      background-color: #f0f2f5;
    }
    button {
      background-color: #397ae8;
      color: #fff;
      padding: 9px 20px;
      border: none;
      border-radius: 3px;
      font-family: inherit;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
  }
  .staticHeader {
    margin-bottom: 35px;
  }
  .ProductsSearch {
    margin-left: 15px;
    position: relative;
    width: 360px;
    input {
      height: 40px;
      border: 1px solid #bbc1c8;
      border-radius: 6px;
      width: 400px;
      padding: 11px;
      font-family: inherit;
      outline: none;
      &::placeholder {
        font-family: inherit;
        color: $gray;
      }
    }
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0;
      border: none;
      height: 13px;
      padding: 0 11px;
      background-color: transparent;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .productStatuses {
    margin-left: 15px;
    width: 215px;

    [class*="-control"] {
      height: 40px;
      border-radius: 6px !important;
      [class*="-container"] {
        height: 40px;
      }
      [class*="-ValueContainer"] {
        overflow: initial;
      }
    }
  }
  .allPagination {
    display: flex;
    margin-left: auto;
    margin-right: 26px;
    align-items: center;
    margin-bottom: 85px;
    margin-top: 150px;
    [class*="pagination"] {
      margin-bottom: 0;
      [class*="page-item"] {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          [class*="page-link"] {
            font-size: 18px;
          }
        }
        [class*="page-link"] {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &[class*="active"] {
          [class*="page-link"] {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &[class*="disabled"] {
          opacity: 0.5;
        }
      }
    }
    .ProductsSeries {
      margin-left: 40px;
      display: flex;
      .ProductsSeriesItem {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 16px;
          margin-right: 10px;
        }
        .ProductsSeriesItemSelect {
          width: 70px;
          [class*="indicatorContainer"] {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .ProductsTable {
    .producstTableRoW {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      .productCol {
        padding-right: 15px;
        padding-left: 15px;

        &.productId {
          flex: 1 0 6%;
        }
        &.productNameImage {
          position: relative;
          flex: 1 0 12%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          &:hover {
            .textItem {
              button {
                visibility: visible !important;
                opacity: 1 !important;
              }
            }
          }
        }
        &.viewerMode {
          flex: 1 0 70%;
        }
        &.productCount {
          flex: 1 0 8%;
          // max-width: 9%;
        }
        &.productType {
          flex: 1 0 7%;
          // max-width: 7%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 20%;
            max-width: 20%;
          }
        }
        &.productDate {
          flex: 1 0 10%;
          // max-width: 11%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 34%;
            max-width: 34%;
          }
        }
        &.productCode {
          flex: 1 0 7%;
          // max-width: 8%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 18%;
            max-width: 18%;
          }
          span {
            visibility: hidden;
            opacity: 0;
          }
          &:hover {
            span {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        &.productPrice {
          flex: 1 0 8%;
          // max-width: 8%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 18%;
            max-width: 18%;
          }
        }
        &.productWarehouse {
          flex: 1 0 9%;
          // max-width: 9%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 18%;
            max-width: 18%;
          }
        }
        &.productSize {
          flex: 1 0 7%;
          // max-width: 9%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 19%;
            max-width: 19%;
          }
        }
        &.productStatus {
          flex: 1 0 5%;
          // max-width: 13%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 15%;
            max-width: 15%;
          }
        }
        &.productEdit {
          flex: 1 0 7%;
          // max-width: 7%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 17%;
            max-width: 71%;
          }
        }
        &.productDelete {
          flex: 1 0 5%;
          // max-width: 5%;
          @media all and (max-width: 767.98px) {
            flex: 0 0 15%;
            max-width: 15%;
          }
        }
      }
    }
    .productsTableHeader {
      background-color: #f0f2f5;
      padding: 11px 13px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 3px;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        img {
          margin-left: 7px;
        }
      }
    }
    .productsTableItem {
      margin-bottom: 4px;
      padding: 11px 13px;
      background-color: #fff;
      &:nth-child(even) {
        background: rgba(240, 242, 245, 0.8);
      }
      &:hover {
        background-color: #377fea12;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 0;
        word-break: break-all;
      }
      .nameImage {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
      .swichItem {
        span {
          margin-left: 0;
          margin-right: 20px !important;
        }
      }
      .productsActions {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          border: 0;
          outline: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          background-color: transparent;
          transition: 0.3s;
          &:hover {
            background-color: rgba(#dce0e5, 0.5);
          }
        }
      }
    }
  }
  .textItem {
    p {
      display: block;
      align-items: center;
      width: 100%;
      &.copied {
        color: #379fff;
        svg {
          path {
            fill: #379fff;
          }
        }
      }
      button {
        &.nearText {
          padding: 0 0 0 12px;
          margin: 0;
          top: 0;
          right: initial;
        }
        position: absolute;
        padding: 0;
        top: 0;
        right: 0;
        margin: 0;
        background-color: transparent;
        outline: none;
        display: inline-block;
        border: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  .salaryContainer {
    display: inline-flex;
    align-items: center;
    .popUp {
      position: absolute;
      background-color: white;
      width: fit-content;
      box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
      top: 0;
      left: 43px;
      border-radius: 8px;
      border-color: blue;
      z-index: 99999;
      padding: 11px 20px;
      border-radius: 6px;
      visibility: hidden;
      opacity: 0;
      transition: 0.1s;
      p {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        &:not(:first-child) {
          margin: 0 !important;
        }
        span {
          color: light black;
          font-weight: 400;
        }
      }
    }
  }
  .salaryContainer {
    cursor: pointer;
    &:hover {
      .popUp {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .picturePopUpContainer {
    position: relative;
    .picturePopUp {
      position: absolute;
      background-color: #ffffffff;
      width: fit-content;
      box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
      top: 0;
      border-radius: 50%;
      border-color: blue;
      z-index: 99999;
      left: 39px;
      padding: 11px 20px;
      visibility: hidden;
      opacity: 1;
      transition: 0.1s;
      p {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        &:not(:first-child) {
          margin: 0 !important;
        }
        span {
          color: light black;
          font-weight: 400;
        }
      }
    }
  }
  .picturePopUpContainer {
    cursor: pointer;
    &:hover {
      .picturePopUp {
        visibility: visible;
        opacity: 2;
      }
    }
  }
}
.infoWrapper {
  margin-left: 10px;
  .productsFullInfo {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 40px;
    .test_real_item {
      align-items: center;
      max-width: 500px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      background-color: #fff;
      border-radius: 6px;
      padding: 11px 10px 11px 0;
      width: calc(100% + 20px);
      .testSide {
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 50%;
        max-width: 50%;
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          margin: 0;
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            min-width: 16px;
            margin-right: 10px;
          }
        }
      }
      .realSide {
        border-left: 1px solid #43425d;
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 50%;
        max-width: 50%;
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 16px;
            height: 16px;
            min-width: 16px;
            margin-right: 10px;
          }
        }
      }
    }
    .wareHouseCount {
      margin-left: 22px;
      border-radius: 6px;
      padding: 11px 22px;
      background-color: #364364;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        margin: 0;
      }
    }
  }
}
.ProductsSearch {
  .inputs {
    display: flex;
    gap: 10px;
    row-gap: 20px;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .selectFilter {
      max-width: 100%;

      .title {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #252733;
        margin-bottom: 5px;
      }
    }

    .ProductsSearch {
      position: relative;

      button {
        position: absolute;
        right: 0;
        margin: 0;
        border: none;
        height: 40px;
        padding: 0 11px;
        background-color: transparent;
        outline: none;
      }
    }

    .searchFilter {
      position: relative;
      padding-top: 25px;

      input {
        box-sizing: border-box;
        outline: none;
        padding: 10px 30px 10px 10px;
        width: 100%;
        height: 40px;
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #c1cada;
        border-radius: 6px;

        &::placeholder {
          color: #98a2b2;
        }
      }
    }

    [class*="-control"] {
      border-radius: 6px !important;
      height: 40px;
      border-color: #c1cada !important;
    }

    [class*="-ValueContainer"] {
      overflow: initial;
    }

    [class*="-Input"] {
      position: static;
    }
  }

  .icons {
    display: flex;

    span.searchIcon {
      border-left: 1px solid #c1cada;
      padding-left: 3px;
    }
  }
}
.label {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
  margin-bottom: 5px;
}
