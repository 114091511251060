.height-preserving-container:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  background: #333851;
  border: 4px solid mediumpurple;
  box-sizing: border-box;
  border-radius: 8px;
  color: #cdd5ee;
  font-size: 30px;
  font-family: "Vibur", cursive;
  user-select: none;

  /* center align text */
  display: flex;
  justify-content: center;
  align-items: center;
  .itemEditButton {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    right: 7px;
    top: 4px;
    height: 40px;
    width: 40px;
  }
}

.item.is-dragging {
  background: #515b7d;
  border-color: MediumSeaGreen;
  box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px MediumSeaGreen;
}
