.trItem {
  &:nth-child(even) {
    background: rgba(240, 242, 245, 0.8);
  }

  &:hover {
    background-color: rgba(55, 127, 234, 0.0705882353);
  }

  .tdItem {
    padding: 18px 10px;
    &:last-child {
      position: sticky;
      z-index: 3;
      right: 0;
    }
    &.accountant {
      display: flex;
      justify-content: center;
    }
    &.noaccountant {
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:last-child {
        position: sticky;
        z-index: 3;
        background-color: #fff;
      }
      &:nth-last-child(3) {
        right: 260px;
        &::after {
          background-color: #fff;
          position: absolute;
          top: 0;
          bottom: -1px;
          left: 0;
          width: 8px;
          transform: translateX(-100%);
          transition: box-shadow 0.3s;
          content: "";
          pointer-events: none;
          box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
        }
      }
      &:nth-last-child(2) {
        right: 140px;
      }
      &:last-child {
        right: 0;
      }
    }
    .imageWrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      margin: 0;
      img {
        width: 34px;
        height: 34px;
        object-fit: cover;
      }
      .textItem {
        p {
          display: block;
          align-items: center;
          width: 100%;
          &.copied {
            color: #379fff;
            svg {
              path {
                fill: #379fff;
              }
            }
          }
          button {
            position: absolute;
            padding: 0;
            top: 0;
            right: 0;
            margin: 0;
            background-color: transparent;
            outline: none;
            display: inline-block;
            border: 0;
            visibility: hidden;
            opacity: 0;
          }
        }
      }
      .popupName {
        position: absolute;
        background-color: white;
        display: flex;
        align-items: center;
        width: fit-content;
        min-width: 280px;
        box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.16);
        top: 0;
        left: 43px;
        z-index: 99999;
        padding: 0px 5px;
        border-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transition: 0.1s;
        .copied {
          font-weight: 600;
          color: green;
        }
      }

      .picturePopUpContainer {
        position: relative;
        .picturePopUp {
          position: absolute;
          background-color: #fff;
          width: fit-content;
          box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
          top: 0;
          border-radius: 50%;
          border-color: blue;
          z-index: 99999;
          left: 39px;
          visibility: hidden;
          opacity: 1;
          transition: 0.1s;
          p {
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            img {
              width: 200px;
              height: 200px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 0;
            }
            &:not(:first-child) {
              margin: 0 !important;
            }
            span {
              color: black;
              font-weight: 400;
            }
          }
        }
      }
      .picturePopUpContainer {
        cursor: pointer;
        &:hover {
          .picturePopUp {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    p,
    button {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
    }
    .importBtn {
      background: #ffffff;
      border: 1px solid #379fff;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
      border-radius: 3px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #379fff;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      height: 36px;
      svg {
        display: inline-block;
        min-width: 30px;
        min-height: 30px;
        padding: 5px;
        padding-right: 5px;
      }
    }
    .exportBtn {
      background: #ffffff;
      border: 1px solid #ff6565;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
      border-radius: 3px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #ff6565;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      height: 36px;
      svg {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        min-width: 27px;
        min-height: 27px;
      }
    }
    .checkBtn {
      background: #ffffff;
      border: 1px solid #252733;
      box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
      border-radius: 3px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #252733;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      height: 36px;
      width: 100%;
    }
    &:hover {
      .textItem {
        button {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }
}
