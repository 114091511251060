@import "../../styles/vars";
.DomainSection {
    @media all and (max-width: 767.98px) {
        padding-bottom: 100px;
    }
    @media all and (min-width: 320px) and (max-width: 1024px) {
        background-color: #fff;
    }
    .domainHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #f0f2f5;
        margin-bottom: 35px;
        h2 {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #252733;
            padding: 25px;
            cursor: pointer;
            background-color: #f0f2f5;
        }
    }
    .DomainContentBig {
        margin-top: 20px;
        .DomainAdder {
            display: flex;
            align-items: center;
            input {
                flex-grow: 1;
                height: 40px;
                border: 0.5px solid $gray;
                padding-left: 20px;
                margin-right: 10px;
                border-radius: 6px;
                &::placeholder {
                    color: $gray;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                }
                &:focus {
                    outline: none;
                }
            }
            button {
                background-color: $blue;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                border-radius: 6px;
                border: none;
                padding: 0;
                svg {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .DomainsContainer {
        max-width: 450px;
        .DomainsContent {
            margin-top: 20px;
            .DomainsItem {
                padding: 15px 5px;
                &:nth-child(even) {
                    background: rgba(240, 242, 245, 0.8);
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
                button {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #edeff2;
                    padding: 0;
                    border: none;
                    border-radius: 50%;
                    &:hover {
                        background-color: rgba(65, 69, 74, 0.251);
                    }
                }
            }
        }
    }
}
