.cardHeaderDropDown {
    position: absolute;
    top: 9px;
    right: 5px;
    z-index: 1;
    &[class*="show"] {
        [class*="dropdownToggle"] {
            background-color: rgba(#bbc1c8, 0.2);
        }
    }
    [class*="dropdownToggle"] {
        transform: rotate(90deg);
        width: unset;
        height: unset;
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        svg {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 4px;
        }
    }

    [class*="dropdownList"] {
        position: unset !important;
    }
    [class*="ddownItemContent"] {
        top: 14px !important;
        right: -12px !important;
        left: auto !important;
    }
}
