.container {
  min-height: calc(100vh - 40px);
}

.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f0f2f5;
  margin-bottom: 35px;
  margin-left: 15px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #252733;
    padding: 25px;
    cursor: pointer;
    margin-bottom: 0;
    background-color: #f0f2f5;
  }
}

.header {
  font-size: 24px;
  font-weight: 20px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.table {
  margin-top: 50px;
  padding: 10px 0;
  .tableWrapper {
    overflow: auto hidden;
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        tr {
          background: #f0f2f5;
          th {
            padding: 6px 7px 6px 6px;
            background: #f0f2f5;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #7e8295;
          }
        }
      }
    }
  }
}

.allPagination {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  margin-top: 36px;
  justify-content: flex-end;
  :global {
    .pagination {
      margin-bottom: 0;
      .page-item {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          .page-link {
            font-size: 18px;
          }
        }
        .page-link {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &.active {
          .page-link {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .ProductsSeries {
    margin-left: 40px;
    display: flex;
    .ProductsSeriesItem {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 16px;
        margin-right: 10px;
      }
      .ProductsSeriesItemSelect {
        width: 70px;
        [class*="indicatorContainer"] {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.z4 {
  position: relative;
  z-index: 4;
}

.z3 {
  position: relative;
  z-index: 3;
}

.z2 {
  position: relative;
  z-index: 2;
}

.z1 {
  position: relative;
  z-index: 1;
}

.z0 {
  position: relative;
  z-index: 0;
}

.resetButton {
  outline: 0;
  border: none;
  background-color: #56aafe;
  color: #fcfcfc;
  padding: 10px 25px;
  border-radius: 10px;
  margin-right: 20px;
  &:disabled {
    opacity: 0.5;
  }
}
.selects {
  display: flex;
  width: 100%;
  .region {
    width: calc(50% - 20px);
    margin: 20px 20px 0 0;
  }
  .period {
    width: 50%;
    margin: 20px 20px 0 0;
  }
  .productIds {
    width: 50%;
    position: relative;
    margin-top: 20px;
  }
}

.ignoreOutOfIntervalField {
  position: relative;
  display: flex;
  margin-top: 24px;
  // width: 100%;
  align-items: center;
  .label {
    width: 360px;
  }
}
.datePickers {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .clearButton {
    outline: 0;
    border: 0;
    background-color: #379fff;
    color: #fcfcfc;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  input {
    width: calc(100% - 20px);
    padding: 6px;
    outline: 0;
  }
}

.backButton {
  background-color: #379fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }
}
