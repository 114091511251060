.ddownItem {
  width: 28px;
  height: 28px;

  .ddownItemContent {
    border-radius: 6px;
    padding: 19px 14px;
    border-radius: 6px;
    min-width: 250px !important;
    border: none;
    // z-index: 99999999;
    background-color: rgba(0, 0, 0, 0);
    button {
      outline: none;
      display: block;
      border: none;
      background-color: transparent;
      width: 100%;
      padding: 0;
      text-align: left;
      padding: 10px 22px;
      border-radius: 0 !important;
    }
  }
}

.dropdownToggle {
  width: 28px;
  height: 28px;
  &:hover {
    cursor: pointer;
    z-index: 99999999;
  }
}
.dropdownToggle::before,
.dropdownToggle::after {
  content: "";
  display: none;
}


