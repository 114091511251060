.selectWrapper {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        label {
            display: block !important;
            margin-bottom: 4px !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
        }
        > li {
            position: relative;

            .checkWrapper {
                cursor: pointer;
                position: relative;
                input {
                    cursor: pointer;
                    outline: none;
                    padding: 10px 30px 10px 10px;
                    width: 100%;
                    font-family: "Roboto", sans-serif !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    border: 1px solid #c1cada;
                    border-radius: 6px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: 35px;
                    top: 50% !important;
                    transform: translateY(-50%) !important;
                    height: calc(100% - 15px);
                    width: 1px;
                    background-color: #c1cada;
                }
                svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                    right: 0;
                    width: 14px;
                    right: 12px;
                    path {
                        fill: #98a2b2;
                    }
                }
            }
            ul {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background: #ffffff;
                border: 1px solid #c1cada;
                z-index: 1;
                li {
                    button {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 14px 20px;
                        border: 0;
                        outline: none;
                        background-color: transparent;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        cursor: pointer;
                        transition: 0.3s;
                        &:hover {
                            background-color: #fafbff;
                        }
                    }
                }
                .noDataItem {
                    margin: 0;
                    padding: 10px;
                }
            }
        }
    }
}
