.customInputDatapicker {
    min-width: 80px;
    background-color: rgba(#BBC1C8, 0.3);
    border-radius: 3px;
    border: 0;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #43425D;
    outline: none;
    &:focus {
        outline: none;
    }
    span {
        margin-left: 17px;
        svg {
            path {
                fill: #BBC1C8;
            }
        }
    }
}