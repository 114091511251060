.welcome_page {
    position: relative;
    background-color: #3f77fb;
    overflow: hidden;
    @media (max-width: 777.98px) {
        background-color:transparent
    }
    > svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -50%);
        @media (max-width: 777.98px) {
            display: none;
        }
        path {
            animation-iteration-count: infinite;
            animation-duration: 30s;
            animation-timing-function: linear;
            animation-name: rotateBg;
            transform-origin: center center;
            fill-opacity: 0.2;
            @keyframes rotateBg {
                0% {
                    transform: rotate(0);
                }
                0% {
                    transform: rotate(-360deg);
                }
            }
            @-webkit-keyframes rotateBg {
                0% {
                    transform: rotate(0);
                }
                0% {
                    transform: rotate(-360deg);
                }
            }
        }
    }
    cursor: url("./assets/cursor.svg"), auto;

    .welcomRow {
        display: flex;
        height: calc(100vh - 47px);
        .leftSide {
            background-color: #fff;
            flex: 0 0 40%;
            .leftContent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                margin-left: 50px;
                @media (min-height: 640px) and (max-height: 900px) {
                    zoom: 0.898;
                }
                .title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 105px;
                    margin-bottom: 0;
                }
                .subTitle {
                    font-weight: 700;
                    font-size: 60px;
                    @media (max-width: 777.98px) {
                        font-size: 40px;
                    }
                }
                .description {
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 43px;
                    max-width: 441px;
                    @media (max-width: 777.98px) {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
                .clickBlock {
                    margin-top: 15px;
                    .clickContainer {
                        position: relative;
                        display: inline-block;
                        > svg {
                            width: 138px;
                            height: 138px;
                        }
                        button {
                            &:hover {
                                cursor: url("./assets/cursor-pointer.svg"), auto;
                            }
                        }
                        .clickHere {
                            position: absolute;
                            top: -20px;
                            left: 70px;
                            > button {
                                border: 0;
                                outline: none;
                                background-color: transparent;
                                padding: 0;
                                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='86' height='86' viewBox='0 0 86 86' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='43' cy='43' r='39.2609' fill='white' stroke='%234077FA' stroke-width='7.47826'/%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: center;
                                background-size: 86px;
                                animation-iteration-count: infinite;
                                animation-duration: 5s;
                                animation-timing-function: linear;
                                animation-name: rotateText;
                                transform-origin: center center;

                                @keyframes rotateText {
                                    0% {
                                        transform: rotate(0);
                                    }
                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            }
                            .clickMessage {
                                position: absolute;
                                top: 100%;
                                left: 100%;
                                background: #ffffff;
                                box-shadow: 0px 4px 10px rgba(159, 168, 177, 0.4);
                                border-radius: 3px;
                                padding: 14px 17px 56px;
                                min-width: 300px;
                                display: flex;
                                flex-direction: column;
                                @media (max-width: 777.98px) {
                                    left: -25px;
                                }

                                .textItems {
                                    p {
                                        margin: 0;
                                        &:first-child {
                                            font-weight: 700;
                                            font-size: 22px;
                                            line-height: 27px;
                                        }
                                        &:last-child {
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 17px;
                                        }
                                    }
                                }

                                .buttonWrapper {
                                    position: absolute;
                                    bottom: 10px;
                                    right: 17px;
                                    margin-top: 14px;
                                    display: inline-block;
                                    margin-left: auto;
                                    &:hover {
                                        cursor: url("./assets/cursor-pointer.svg"), auto;
                                    }
                                    span {
                                        border: 0;
                                        outline: none;
                                        position: relative;
                                        font-weight: 700;
                                        font-size: 18px;
                                        line-height: 22px;
                                        padding: 6px 27px;
                                        background-color: #f1d624;
                                        display: block;
                                        z-index: 2;
                                        &:hover {
                                            cursor: url("./assets/cursor-pointer.svg"), auto;
                                        }
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        bottom: -5px;
                                        left: -5px;
                                        background-color: #000;
                                        z-index: 1;
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .rightSide {
            flex: 1;
            @media (min-height: 640px) and (max-height: 900px) {
                zoom: 0.898;
            }
            @media (max-width: 767.98px) {
                display: none;
            }
            .addToList {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;
                max-width: 747px;
                background-color: #fff;
                border-radius: 6px;
                margin: 80px 150px 20px 40px;
                padding: 38px 54px;

                input {
                    width: 100%;
                    border: 3px solid #000;
                    border-radius: 2px;
                    height: 60px;
                    padding: 15px;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 39px;
                }
                button {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 48px;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 31px;
                    border-radius: 2px;
                    background-color: #3f77fb;
                    outline: none;
                    border: none;
                    color: #fff;
                    &:disabled {
                        opacity: 0.5;
                        cursor: not-allowed !important;
                    }

                    &:hover {
                        cursor: url("./assets/cursor-pointer.svg"), auto;
                    }
                }
            }
            .dragDropAreas {
                display: flex;
                width: 100%;
                gap: 10px;
                max-width: 747px;
                margin-left: 40px;
                .dropAbleMain {
                   height: calc(100% - 70px);
                }
                .dropArea {
                    flex: 0 0 calc(50% - 10px);
                    height: 360px;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 10px;
                        padding-right: 15px;
                      }
                
                      &::-webkit-scrollbar-track {
                        background: transparent;
                        border-radius: 20px;
                        border: 16px solid transparent;
                        background-clip: padding-box;
                      }
                
                      &::-webkit-scrollbar-thumb {
                        background: #bbc1c8;
                        border-radius: 20px;
                        border: px solid transparent;
                        background-clip: padding-box;
                      }
                    
                    .areaName {
                        position: sticky;
                        top: 0;
                        background: #f1d624;
                        border-radius: 3px;
                        padding: 14px 15px;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 27px;
                        margin-bottom: 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 56px;
                        &.areaNameDark {
                            background-color: #000;
                            color: #fff;
                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                    .dragItem {
                        margin-bottom: 8px;

                        .innerItem {
                            cursor: url("./assets/cursor-pointer.svg"), auto;
                        }
                    }
                    .innerItem {
                        display: flex;
                        background-color: #fff;
                        padding: 10px;
                        gap: 15px;
                        align-items: center;
                        border-radius: 3px;
                        p {
                            margin: 0;
                            span {
                                display: block;
                                &:first-child {
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 19px;
                                    margin-bottom: 4px;
                                }
                                &:last-child {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                            }
                        }
                        svg {
                            &:last-child {
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .welcome_page_container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        z-index: 2;
        position: relative;

        .welcome_footer {
            margin-top: auto;
            padding: 13px 0;
            background-color: #eeeeee;
            .hiddenLink {
                text-decoration: none;
                color: #212529;
                cursor: default;
            }
            p {
                margin-bottom: 0;
                font-size: 14px;
            }
        }
    }
}
