.selectWrapper {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    > label {
      display: block !important;
      margin-bottom: 4px !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
    }
    > li {
      position: relative;

      .checkWrapper {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        outline: none;
        width: 100%;
        height: 100%;
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #c1cada;
        border-radius: 6px;

        .selelctedCount {
          background-color: rgb(187, 187, 187);
          display: flex;
          align-items: center;
          margin-left: 8px;

          p {
            padding: 0 8px;
            font-size: 14px;
            color: #fff;
            font-weight: 600;
            margin: 0;
          }
          button {
            background-color: #6c757d;
            border: 0;
            padding: 0;
            padding: 4px 8px;
            outline: none;
            svg {
              width: 10px;
              path {
                stroke: #fff;
              }
            }
          }
        }
        input {
          cursor: pointer;
          border: 0;
          height: 100%;
          padding: 0;
          padding: 12px 40px 12px 10px;
          width: 1px;
          flex: 1;
          border-radius: 6px;
        }
        &::after {
          content: "";
          position: absolute;
          right: 35px;
          top: 50% !important;
          transform: translateY(-50%) !important;
          height: calc(100% - 15px);
          width: 1px;
          background-color: #c1cada;
        }
        > svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          right: 0;
          width: 14px;
          right: 12px;
          path {
            fill: #98a2b2;
          }
        }
      }
      ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        background: #ffffff;
        border: 1px solid #c1cada;
        z-index: 1;
        li {
          position: relative;
          .openSubMenu {
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            cursor: pointer;

            svg {
              width: 12px;
              height: 12px;
              transform: rotate(-90deg);
              path {
                fill: #98a2b2;
              }
            }
          }

          label {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 14px 20px;
            border: 0;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            gap: 13px;
            cursor: pointer;
            transition: 0.3s;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #364364;
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 0;
            }
            &:hover {
              background-color: #fafbff;
            }
            span {
              position: relative;
              display: block;
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
              max-width: 16px;
              max-height: 16px;
              border: 1px solid #b8c4d6;
            }
            input {
              display: none;
              &:checked {
                + span {
                  border-color: #397ae8;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80%;
                    height: 80%;
                    background-color: #397ae8;
                  }
                }
              }
            }
            &.partialSelect {
              input {
                + span {
                  display: block;
                  border-color: #397ae8;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80%;
                    height: 2px;
                    background-color: #397ae8;
                  }
                }
              }
            }
          }
        }
        .noDataItem {
          margin: 0;
          padding: 10px;
        }
      }
      .subMenuWrapper {
        .noDataItem {
          text-align: center;
          border: 1px solid #c1cada;
          top: 100%;
          width: 200px;
          position: absolute;
          background-color: #fff;
          margin: 0;
          padding: 10px;
          left: 100%;
          z-index: 2;
        }
      }
      .subMenu {
        top: 100%;
        width: 200px;
        position: absolute;
        left: 100%;
        max-height: 500px;

        li {
          position: relative;

          label {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 14px 20px;
            border: 0;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            gap: 13px;
            cursor: pointer;
            transition: 0.3s;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #364364;
            &:hover {
              background-color: #fafbff;
            }
            span {
              position: relative;
              display: block;
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
              max-width: 16px;
              max-height: 16px;
              border: 1px solid #b8c4d6;
            }
            input {
              display: none;
              &:checked {
                + span {
                  border-color: #397ae8;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80%;
                    height: 80%;
                    background-color: #397ae8;
                  }
                }
              }
            }
            &.partialSelect {
              input {
                + span {
                  display: block;
                  border-color: #397ae8;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80%;
                    height: 2px;
                    background-color: #397ae8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
