.profileContainer {
  z-index: 20;
  position: fixed;
  background-color: #eaedf2;
  top: 70px;
  width: 100%;
  left: 0;
  height: calc(100vh - 150px);
  padding: 18px 10px;
  .profile {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 13px 30px;
    border-radius: 6px;
    .profileImage {
      width: 40px;
      height: 40px;
      margin-right: 19px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .profileName {
      margin-bottom: 10px;
    }
    .profileBalance {
      background-color: #ffc013;
      color: #fff;
      padding: 3px 15px;
      border-radius: 15px;
    }
    .plusPrice {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin: 0 0 0 8px;
    }
    &.settingButton {
      margin-top: 10px;
      cursor: pointer;
      font-size: 14px;
    }
    &.logoutButton {
      margin-top: 10px;
      cursor: pointer;
      color: #F3373D;
      font-size: 14px;
    }
  }
}
@media all and (min-width: 769px) {
  .profileContainer {
    display: none;
  }
}
