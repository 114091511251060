@import "../../../../../../styles/vars";
.filters {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;

  gap: 10px;
  .inputs {
    display: flex;
    gap: 10px;
    row-gap: 20px;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    .selectFilter {
      flex: 0 0 calc(25% - 10px);
      max-width: calc(25% - 10px);
      .title {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #252733;
        margin-bottom: 5px;
      }
    }
    .ProductsSearch {
      position: relative;
      .title {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #252733;
        margin-bottom: 5px;
      }
      button {
        position: absolute;
        right: 0;
        margin: 0;
        border: none;
        height: 40px;
        padding: 0 11px;
        background-color: transparent;
        outline: none;
      }
    }
    .searchFilter {
      position: relative;
      flex: 0 0 calc(25% - 10px);
      max-width: calc(25% - 10px);
      input {
        box-sizing: border-box;
        outline: none;
        padding: 10px 30px 10px 10px;
        width: 100%;
        height: 40px;
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #c1cada;
        border-radius: 6px;
        &::placeholder {
          color: #98a2b2;
        }
      }
    }
    [class*="-control"] {
      border-radius: 6px !important;
      height: 40px;
      border-color: #c1cada !important;
    }
    [class*="-ValueContainer"] {
      overflow: initial;
    }
    [class*="-Input"] {
      position: static;
    }
  }
  .counts {
    display: flex;
    flex: 0 0 100%;
    justify-content: flex-end;
    .count {
      margin-top: 20px;
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      margin-right: 15px;
      padding-right: 15px;
      &:first-child {
        border-right: 1px solid rgba(102, 118, 149, 0.2);
      }
      span {
        color: #379fff;
      }
    }
  }
}
