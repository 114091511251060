.areainfoWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    span {
        font-size: 12px;
        color: #066bc6;
        cursor: pointer;
    }
    p {
        margin: 0;
        font-size: 12px;
        font-weight: 600;
    }
    .areaInfoLeft {
        display: flex;
        align-items: center;
        gap: 13px;
        .areainfoItem {
            display: flex;
            align-items: center;
            gap: 3px;
            div {
                width: 10px;
                height: 10px;
            }
            &:nth-child(2) {
                div {
                    background-color: rgba(#1D9EF9, 0.6);
                }
            }
            &:last-child {
                div {
                    background-color: rgba(#252733, 0.6);
                }
            }
        }
    }
}
