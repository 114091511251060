.filterContent {
  @media all and (max-width: 768px) {
    margin: 0 0 72px;
    width: 100%;
  }
  .selectLabel {
    color: #252733;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 6px;
    font-weight: 400;
    span {
      display: inline-block;
    }
  }
  .filterViewed {
    z-index: 5;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid #f0f2f5;
    .period {
      margin-bottom: 10px;
      min-width: 180px;
      margin-right: 20px;
      width: calc(22% - 20px);
      @media (max-width: 1592px) {
        label {
          line-height : 40px !important;

        }
      
      }
      .datePickers {
        width: 100%;
        display: flex;
        .datePicker {
          width: 50%;
          input {
            width: 100%;
          }
        }
      }
    }
    .crmProduct {
      margin-bottom: 10px;
      min-width: 180px;
      margin-right: 20px;
      width: calc(22% - 20px);
    }
    .productUrl {
      margin-bottom: 10px;
      min-width: 180px;
      margin-right: 20px;
      width: calc(22% - 20px);
    }
    .ignoreOutOfIntervalField {
      margin-top: 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 220px;
      .label {
        width: 100%;
      }
      label {
        width: 50px !important;
      }
    }
    .filterButton {
      background-color: #f0f2f5;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
    }
    .resetFilter {
      background-color: transparent;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      outline: none;
      :disabled {
        cursor: not-allowed;
      }
    }
  }
  .filterHidden {
    z-index: 4;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 10px 22px;
    border-bottom: 2px solid #f0f2f5;
    background-color: #f0f2f5;
    .domain {
      margin-bottom: 10px;
      max-width: 200px;
      min-width: 180px;
      margin-right: 20px;
      width: calc(25% - 20px);
    }
  }
  .createPreset {
    font-weight: 400;
    background-color: #379fff;
    font-size: 20px;
    padding: 8px 23px;
    color: #ffffff;
    border-radius: 3px;
    border: 0;
    outline: none;
    margin-bottom: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    &.changed {
      background-color: #11af80;
    }
  }
  .presetList {
    margin-top: 3px;
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .preset {
      background-color: yellow;
      margin-right: 5px;
      display: flex;
      padding: 2px 8px;
      background-color: #f7f8fa;
      height: 24px;
      &.active {
        background-color: #d5d5d6;
      }
      .presetName {
        overflow-x: hidden;
        margin-right: 30px;
        max-width: 120px;
        min-width: 120px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .deletePreset {
        background-color: #00000000;
        border: 0;
        outline: none;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }
}
