.tableWrapper {
    overflow: auto hidden;
    padding-bottom: 40px;
    margin-bottom: 40px;
    table {
        width: 100%;
        table-layout: fixed;
        thead {
            background: #f0f2f5;
            th {
                padding: 16px 6px;
                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #7e8295;
                    display: flex;
                    gap: 15px;
                    span {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: #f0f2f5;
                }
                td {
                    p {
                        padding: 16px 6px;
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #252733;
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        img {
                            width: 32px;
                            height: 32px;
                            object-fit: cover;
                        }
                    }
                }
                &:last-child {
                    td {
                        [class*="editDeleteWrapper"] {
                            top: -90px;
                        }
                    }
                }
            }
        }
    }
}
