.filterWrapper {
  position: relative;
  z-index: 17;

  .pageTitle {
    padding: 38px 22px;
    font-size: 22px;
    line-height: 20px;
    font-weight: 700;
    border-bottom: 2px solid #f0f2f5;
    box-sizing: border-box;
  }
  .filters {
    padding-top: 10px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f0f2f5;
    padding-bottom: 20px;
    .period {
      margin-right: 20px;
      max-width: 240px;
      width: 25%;
    }
    .crmProduct {
      margin-right: 20px;
      max-width: 400px;
      width: 30%;
    }
    .productUrl {
      margin-right: 20px;
      max-width: 400px;
      width: 30%;
    }
    .resetFilter {
      margin-top: 20px;
      background-color: transparent;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      outline: none;
    }
    .filterButton {
      background-color: #f0f2f5;
      height: 36px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: 0;
      border: none;
      margin-top: 21px;
    }
  }
}
