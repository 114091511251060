.customDatePickerWrapper {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    :global {
        .react-datepicker-popper {
            z-index: 3;
        }
        .react-datepicker {
            font-family: inherit;
            background-color: var(--white);
            border: 1px solid var(--black);
            border-radius: 3px;

            .react-datepicker__triangle {
                display: none;
            }
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__month-text,
            .react-datepicker__quarter-text,
            .react-datepicker__year-text {
                width: 40px;
                margin: 0;
                padding: 0.166rem;
                border-radius: 0;
                color: #364364;
                &:hover {
                    background-color: #066bc6;
                    color: #fff;
                }
                &.react-datepicker__day--selected {
                    background-color: rgba(56, 141, 255, 0.4);
                    color: var(--white);
                }
            }
            .react-datepicker__day-name {
                color: rgba(34, 48, 60, 0.5);
                &:hover {
                    background-color: transparent;
                    color: rgba(34, 48, 60, 0.5);
                }
            }
            .react-datepicker__day--in-range {
                background: rgba(56, 141, 255, 0.4);
                color: #364364;
                font-weight: 500;
                font-family: "Inter", sans-serif !important;
            }
            .react-datepicker__header {
                background-color: var(--white);
                border-bottom: 0;
            }
            .react-datepicker__day-names {
                padding: 0 14px;
            }
        }
        .react-datepicker__day--outside-month {
            color: transparent !important;
            pointer-events: none;
        }
        .react-datepicker-wrapper {
            input {
                height: 40px;
                appearance: none;
                width: 100%;
                border: 1px solid var(--darkGrey);
                border-radius: 6px;
                padding: 12px 19px;
                color: #364364;
                transition: 0.3s;
                background-color: var(--secondWhite);
            }
        }
    }
    .datepickerHeader {
        .headerContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 14px;
            .montsWrapper,
            .yearsWrapper {
                display: flex;
                align-items: center;
                button {
                    margin-top: 0;
                    background-color: transparent;
                    outline: 0;
                    border: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    &:first-child {
                        svg {
                            width: 15px;
                            transform: rotate(90deg);
                            path {
                                fill: #364364;
                            }
                        }
                    }
                    &:last-child {
                        svg {
                            width: 15px;
                            transform: rotate(-90deg);
                            path {
                                fill: #364364;
                            }
                        }
                    }
                    &:disabled {
                        opacity: 0.5;
                    }
                }
                p {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 17px;
                    color: #364364;
                    margin: 0;
                }
            }
        }
    }
    .selectWrapper {
        list-style-type: none;
        position: relative;
        top: unset;
        left: unset;
        border: 0;
        > li {
            position: relative;
            padding: 0 10px;
            cursor: pointer;
            ul {
                position: absolute;
                top: calc(100% + 4px);
                left: -20px;
                width: calc(100% + 40px);
                list-style-type: none;
                border: 1px solid var(--blue);
                border-radius: 6px;
                height: 210px;
                overflow: auto;
                background-color: var(--white);
                display: none;
                z-index: 1;
                &.openedDropDown {
                    display: block;
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                > li {
                    width: 100%;
                    padding: 12px 2px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    text-align: center;
                    cursor: pointer;
                    color: var(--black);
                    &:hover {
                        background-color: var(--gray);
                    }
                }
            }
        }
    }
}
