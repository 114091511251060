.tableContainer {
    margin-top: 80px;
    .tableData {
        .dataHeader {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 10px;
            box-shadow: 0px -2px 6px rgba(102, 118, 149, 0.15);
            .headerLeft {
                display: flex;
                gap: 16px;
                align-items: center;
                .lockedContent {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    padding-left: 16px;
                    padding-right: 16px;
                    border-left: 1px solid #b8c4d6;
                    border-right: 1px solid #b8c4d6;
                }
                .dataItem {
                    align-items: center;
                    display: flex;
                    gap: 4px;
                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #252733;
                        &:last-child {
                            font-weight: 600;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .headerRight {
                .rightInfo {
                    font-size: 14px;
                    font-weight: 600;
                    color: #252733;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    p {
                        margin: 0;
                        text-decoration: underline;
                    }
                    span {
                        font-weight: 700;
                        color: #1d9ef9;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
