.chartControls {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 20px;
    p {
        margin: 0;
    }
    .chartItem {
        display: flex;
        align-items: center;
        gap: 7px;
        div {
            width: 26px;
            height: 26px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #364364;
        }
        span {
            color: #066bc6;
            font-size: 12px;
            align-self: flex-end;
            margin-bottom: 3px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 2px;
        }
       
        &:last-child {
            div {
                background-color: #066BC6;
            }
        }
    }
}
.hiddenText {
    display: none;
}