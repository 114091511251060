.createNewBoar {
    margin-top: 13px;
    .CreateBoardButton {
        padding: 15px 30px;
        border-radius: 6px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #379fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        background: rgba(55, 159, 255, 0.1);
        border-radius: 3px;
        border: none;
        outline: none !important;
    }
}
