.boardsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f0f2f5;
    margin-bottom: 35px;
    h2 {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #252733;
        padding: 25px;
        cursor: pointer;
        margin-bottom: 0;
        background-color: #f0f2f5;
    }
}
.boardnNameContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 15px;
    align-items: center;

    .existBoardItemContent {
        position: relative;
        display: flex;
        padding: 0 10px;
        @media all and (min-width: 320px) and (max-width: 1024px) {
            margin-bottom: 10px;
            width: 100%;
            padding: 0;
        }
        @media all and (min-width: 1024px) {
            margin-bottom: 20px;
        }
        &:last-of-type {
            margin-right: 10px;
            @media all and (max-width: 767.98px) {
                margin-right: 0;
            }
        }
    }
    .existBoardItem {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        color: #000;
        padding: 24px 15px;
        border: 1px solid #98a2b2;
        border-radius: 3px;
        padding: 15px 30px 15px 30px;
        @media all and (max-width: 767.98px) {
            padding: 17px 15px;
            background-color: #fff;
        }
        text-decoration: none;
        border-radius: 6px;
        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        .dotsButton {
            margin-left: 26px;
            margin-right: 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            outline: none;
            margin-right: -30px;
        }
    }
}
.existBoardItem {
    @media all and (min-width: 320px) and (max-width: 1024px) {
        
        width: 100%;
        background-color: #fff;

        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        .dotsButton {
            margin-right: 0 !important;
        }
        a {
            display: block;
            padding: 17px 13px;
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            color: #000;
            text-decoration: none;
        }
    }
}

.boardActionsContent {
    min-width: 240px;
    position: absolute;
    top: 93%;
    transform: translateY(-50%);
    left: calc(100% + 15px);
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    @media all and (max-width: 767.98px) {
        left: 50%;
        transform: translate(-50%, -50%);
    }
    > button {
        outline: none;
        width: 100%;
        display: flex;
        border: none;
        background-color: transparent;
        color: #43425d;
        font-size: 14px;
        font-weight: 600;
        padding: 15px 18px;
        &:hover {
            background: rgba(240, 242, 245, 0.8)
        }
    }
}

.boardNameButton {
    text-align: center;
}
