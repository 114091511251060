.mainContainer {
  background-color: #fff;
  .tabs {
    display: flex;
    border-bottom: 2px solid #F0F2F5;
    .tab {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #252733;
      padding: 25px;
      cursor: pointer;
      &.active {
        background: #F0F2F5;
      }
    }
  }
}
