@import "../../../../styles/vars";
.salaryRow_item {
    color: #397ae8;
}
.salaryTable {
    padding: 25px 0;
    border-radius: 6px;
    border: 1px solid #dce0e5;
    box-shadow: 0 3px 6px rgba($black, 0.16);
    overflow: hidden;
    margin-bottom: 20px;
    @media all and (max-width: 767.98px) {
        width: 1150px;
    }

    .salaryTableHeader {
        margin-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 20px;
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
        }
    }
    .salaryTableRow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
        .salaryTableRow_item {
            height: 30px;
            flex: 0 0 14%;
            max-width: 14%;
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
            align-items: center;
            p {
                margin-bottom: 0;
            }
            &.salaryTableRow_item_datePicker {
                flex: 0 0 10%;
                max-width: 10%;
            }
            &.salaryTableRow_item_salaryStatus {
                flex: 0 0 20%;
                max-width: 20%;
                p {
                    text-align: right;
                }
                .paid {
                    color: $black;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 10px;
                    }
                }
                .forPay {
                    color: $white;
                    padding: 10px 40px;
                    border: 2px solid #397ae8;
                    background-color: #73a4f8;
                    height: 40px;
                }
            }
            &.salaryTableRow_item_bonus {
                input {
                    width: 150px;
                    color: #000;
                    &::placeholder {
                        color: #000;
                    }
                    background-color: #f2f3f7;
                }
                .enabledInput {
                    background-color: transparent;
                    padding-top: 10px;
                    min-width: 80px;
                    max-width: 120px;
                    display: inline-block;
                    border: 0;
                }
            }
            &:last-child {
                button {
                    margin-left: auto;
                    border-radius: 6px;
                    font-size: 16px;
                    background-color: #397ae8;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    color: $white;
                    height: 50px;
                    outline: none;
                }
            }
        }
    }
    .salaryTableBody {
        padding: 20px 30px;
        border-top: 1px solid #eaedf2;
        &:hover{
            background-color: rgba(55, 127, 234, 0.0705882353);
        }
        p {
            height: 50px;
            border-radius: 6px;
            padding: 14px 20px 20px 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            transition: 0.3s;
            margin: 0;
        }
        > span {
            font-size: 18px;
            display: block;
            width: 100%;
            text-align: center;
            font-weight: 700;
        }
    }
}

.staticInput{
    border: #3f77fb;
    border-radius: 6px;
    height: 35px;
    margin-top: 5px;
}
.cursor{
    cursor: not-allowed!important;
}

