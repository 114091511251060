.settings {
  position: fixed;
  width: 160px;
  height: 100vh;
  background-color: #FFF;
  right: 0;
  top: 70px;
  ul {
    padding: 10px 16px;
    list-style: none;
    li {
      margin-bottom: 14px;
      button {
        padding: 0;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        color: #43425D;
        border: none;
      }
    }
  }
}
