.filterDotsWrapper {
    position: relative;
    &[class*="text_"] {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
    }
    
    > button {
        border: 0;
        padding: 0;
        background-color: transparent;
        display: flex;
        margin-left: auto;
        padding: 0 18px;
        outline: none;
    }
    &.left {
        .editDeleteWrapper {
            left: calc(100% - 15px);
        }
    }
    .editDeleteWrapper {
        position: absolute;
        top: calc(100% + 4px);
        right: 15px;
        background-color: #fff;
        min-width: 178px;
        max-width: 179px;
        filter: drop-shadow(0px 3px 6px rgba(54, 67, 100, 0.161));
        z-index: 1;
        button {
            outline: none;
            padding: 12px 20px;
            width: 100%;
            background-color: transparent;
            border: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #252733;
            text-align: left;
            &:hover {
                background-color: #fafbff;
            }
        }
    }
}
