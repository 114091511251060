.compaingWrapper {
    margin-top: 30px;
    .campingFilters {
        input {
            outline: none;
            padding: 10px 30px 10px 10px;
            width: 100%;
            height: 40px;
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            border: 1px solid #c1cada;
            border-radius: 6px;
        }
        .searchWithButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .inputWrapper {
                flex: 0 0 360px;
            }
            button {
                outline: none;
                border: 0;
                background: #379fff;
                border-radius: 6px;
                padding: 13px 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
            }
        }
        .filterList {
            margin-top: 34px;
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
            .filterItem {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }
    }
    .tableWrapper {
        margin-top: 30px;
    }
}
