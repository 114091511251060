@import "../../styles/vars";
.ProductsSection {
    height: 100%;
    display: flex;
    flex-direction: column;
    .statesHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #f0f2f5;
        margin-bottom: 35px;
        h2 {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #252733;
            padding: 25px;
            cursor: pointer;
            background-color: #f0f2f5;
        }
        button {
            background-color: #397ae8;
            color: #fff;
            padding: 9px 20px;
            border: none;
            border-radius: 3px;
            font-family: inherit;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
        }
    }

    .ProductsSearch {
        margin-left: 15px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        input {
            height: 40px;
            border: 1px solid #bbc1c8;
            border-radius: 6px;
            width: 400px;
            padding: 11px 11px 11px 40px;
            font-family: inherit;
            outline: none;
            &::placeholder {
                font-family: inherit;
                color: $gray;
            }
        }
        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border: none;
            height: 40px;
            padding: 0 11px;
            background-color: transparent;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .states_filterRow {
        margin-top: 30px;
        margin-left: -5px;
        margin-right: -5px;
        align-items: flex-end;
        :global {
            .col-1 {
                flex: 0 0 210px;
                max-width: 210px;
                padding-left: 5px;
                padding-right: 5px;
                &:last-child {
                    flex: 0 0 160px;
                    max-width: 160px;
                }
            }
        }
    }
    .productStatuses {
        margin-bottom: 30px;
        .label {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
            margin-bottom: 5px;
        }

        [class*="-control"] {
            height: 40px;
            border-radius: 6px !important;
            * {
                font-size: 14px !important;
            }
            [class*="-container"] {
                height: 40px;
            }
            [class*="-ValueContainer"] {
                overflow: initial;
            }
        }
        .searchClear {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            padding: 10px !important;
            background-color: transparent !important;
            border-radius: 3px !important;
            height: 38px !important;
            white-space: nowrap !important;
            font-size: 12px !important;
            color: #212529 !important;
            border: 0 !important;
            outline: none;
            opacity: .65;
            span {
                padding-right: 8px;
            }
        }
    }
    .allPagination {
        display: flex;
        margin-left: auto;
        margin-right: 26px;
        align-items: center;
        margin-bottom: 85px;
        margin-top: 150px;
        [class*="pagination"] {
            margin-bottom: 0;
            [class*="page-item"] {
                &:not(:last-child) {
                    margin-right: 7px;
                }
                &:first-child,
                &:last-child {
                    [class*="page-link"] {
                        font-size: 18px;
                    }
                }
                [class*="page-link"] {
                    width: 30px;
                    height: 30px;
                    border-radius: 3px;
                    border: 1px solid #e8e9ec;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #43425d;
                    font-size: 10px;
                }
                &[class*="active"] {
                    [class*="page-link"] {
                        color: #fff;
                        border-color: #397ae8;
                        background-color: #397ae8;
                    }
                }
                &[class*="disabled"] {
                    opacity: 0.5;
                }
            }
        }
        .ProductsSeries {
            margin-left: 40px;
            display: flex;
            .ProductsSeriesItem {
                display: flex;
                align-items: center;
                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    margin-right: 10px;
                }
                .ProductsSeriesItemSelect {
                    width: 70px;
                    [class*="indicatorContainer"] {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .ProductsTable {
        .producstTableRoW {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: -15px;
            margin-right: -15px;
            .productCol {
                padding-right: 15px;
                padding-left: 15px;
                &.productNameImage {
                    flex: 0 0 24%;
                    max-width: 24%;
                    .productNameCodeWrapper {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .copyItem {
                            background-color: transparent;
                            border: none;
                            outline: none;
                            transition: 0.3s;
                            white-space: nowrap;
                            display: flex;
                            flex-direction: row-reverse;
                            align-items: center;
                            svg {
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:hover {
                                svg {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                            &.copeid {
                                svg {
                                    path {
                                        fill: #397ae8;
                                    }
                                }
                            }
                        }
                    }
                }
                &.productType {
                    flex: 0 0 22%;
                    max-width: 22%;
                }
                &.productDate {
                    flex: 0 0 11%;
                    max-width: 11%;
                }
                &.productCode {
                    flex: 0 0 7%;
                    max-width: 7%;
                }
                &.productPrice {
                    flex: 0 0 16%;
                    max-width: 16%;
                    button {
                        color: #fff;
                        padding: 5px 10px;
                        border: none;
                        border-radius: 6px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        outline: none;
                    }
                    .acceptButton {
                        background-color: #397ae8;
                        margin-right: 13px;
                    }
                    .declineButton {
                        background-color: #ff6166;
                    }
                }
                &.productWarehouse {
                    flex: 0 0 11%;
                    max-width: 11%;
                }
                &.productSize {
                    flex: 0 0 9%;
                    max-width: 9%;
                }
                &.productStatus {
                    flex: 0 0 5%;
                    max-width: 13%;
                }
                &.productEdit {
                    flex: 0 0 7%;
                    max-width: 7%;
                }
                &.productDelete {
                    flex: 0 0 5%;
                    max-width: 5%;
                }
            }
        }
        .productsTableHeader {
            background-color: #f0f2f5;
            padding: 11px 13px;
            border-radius: 6px 6px 0 0;
            margin-bottom: 3px;
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                img {
                    margin-left: 7px;
                }
            }
        }
        .productsTableItem {
            margin-bottom: 4px;
            padding: 11px 13px;
            background-color: #fff;
            &:nth-child(even) {
                background: rgba(240, 242, 245, 0.8);
            }
            &:hover {
                background-color: #377fea12;
            }
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                margin-bottom: 0;
                word-break: break-all;
            }
            .nameImage {
                display: flex;
                align-items: center;
            }
            .swichItem {
                span {
                    margin-left: 0;
                    margin-right: 20px !important;
                }
            }
            .productsActions {
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    border: 0;
                    outline: none;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    height: 36px;
                    background-color: transparent;
                    transition: 0.3s;
                    &:hover {
                        background-color: rgba(#dce0e5, 0.5);
                    }
                }
            }
        }
    }
    .salaryContainer {
        position: relative;
        .popUp {
            position: absolute;
            background-color: white;
            width: fit-content;
            box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
            top: 0;
            left: 43px;
            border-radius: 8px;
            border-color: blue;
            z-index: 99999;
            padding: 11px 20px;
            border-radius: 6px;
            visibility: hidden;
            opacity: 0;
            transition: 0.1s;
            p {
                display: flex;
                justify-content: space-between;
                white-space: nowrap;
                &:not(:first-child) {
                    margin: 0 !important;
                }
                span {
                    color: light black;
                    font-weight: 400;
                }
            }
        }
    }
    .salaryContainer {
        cursor: pointer;
        &:hover {
            .popUp {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .filterDatePickerContent {
        border: 1px solid #a6a6ab;
        padding: 10px;
        border-radius: 6px;
        background-color: $white;
        min-width: 300px;
        [class="react-datepicker"] {
            border: none;
            width: 100%;
        }
        [class*="data-picker-radio"] {
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            justify-content: space-between;
            label {
                font-size: 14px;
                padding-left: 30px;
                &:last-child {
                    margin-left: 5px;
                }
            }
        }
        [class="react-datepicker__day-names"],
        [class="react-datepicker__week"] {
            display: flex;
            justify-content: space-evenly;
        }
        [class*="react-datepicker__day"] {
            border-radius: 0 !important;
        }
        [class="react-datepicker__day-names"] {
            background-color: #f7f9ff;
            margin-top: 5px;
        }
        [class="react-datepicker__month-container"] {
            width: 100%;
        }
        div[class*="react-datepicker__header"] {
            background-color: $white;
        }
        [class*="react-datepicker__navigation"] {
            &:focus {
                outline: none !important;
            }
        }
        [class*="react-datepicker__current-month"] {
            font-size: 14px;
            font-weight: 600;
            margin-top: -3px;
        }
    }

    [class*="react-datepicker__input-container"] {
        input {
            width: 100%;
            height: 40px;
            border-radius: 6px;
            outline: none;
            border: 1px solid #a4a9b8ff;
            font-size: 13px;
            padding-left: 6px;
            color: black;
            &::placeholder {
                text-align: center;
                color: #a4a9b8ff;
            }
        }
    }
    [class*="react-datepicker__close-icon"] {
        display: none !important;
    }
}
.inputs {
    display: flex;
    gap: 10px;
    row-gap: 20px;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .selectFilter {
        flex: 0 0 calc(25% - 10px);
        max-width: calc(25% - 10px);
        .title {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
            margin-bottom: 5px;
        }
    }
    .ProductsSearch {
        display: flex;
        justify-content: flex-end;
        position: relative;
        button {
            position: absolute;
            right: 0;
            margin: 0;
            border: none;
            height: 40px;
            padding: 0 11px;
            background-color: transparent;
            outline: none;
        }
    }
    .searchFilter {
        position: relative;
        flex: 0 0 calc(25% - 10px);
        max-width: calc(25% - 10px);
        padding-top: 25px;
        input {
            box-sizing: border-box;
            outline: none;
            padding: 10px 30px 10px 10px;
            width: 100%;
            height: 40px;
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            border: 1px solid #c1cada;
            border-radius: 6px;
            &::placeholder {
                color: #98a2b2;
            }
        }
    }
    [class*="-control"] {
        border-radius: 6px !important;
        height: 40px;
        border-color: #c1cada !important;
    }
    [class*="-ValueContainer"] {
        overflow: initial;
    }
    [class*="-Input"] {
        position: static;
    }
}
.icons {
    display: flex;
    span.searchIcon {
        border-left: 1px solid #c1cada;
        padding-left: 3px;
    }
}
