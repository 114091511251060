.searchInputWrapper {
    width: 100%;
    position: relative;
    input {
        width: 100%;
        font-family: "Roboto", sans-serif !important;
        font-size: 16px !important;
        &::placeholder {
            color: hsl(0, 0%, 50%) !important;
        }
    }
    .iconWrapper {
        position: absolute !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        height: 100%;
        width: 35px;
        right: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        span {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            &.resetIcon {
                svg {
                    path {
                        stroke: #98a2b2;
                    }
                }
            }
        }
        svg {
            width: 14px;
            height: 14px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        right: 35px;
        top: 50% !important;
        transform: translateY(-50%) !important;
        height: calc(100% - 15px);
        width: 1px;
        background-color: #c1cada;
    }
}
