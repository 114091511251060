.pageHeader {
  .imageWrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #252733;
    margin: 0;
    img {
      width: 34px;
      height: 34px;
      object-fit: cover;
    }

    .picturePopUpContainer {
      position: relative;
      .picturePopUp {
        position: absolute;
        background-color: #fff;
        width: fit-content;
        box-shadow: 2px 2px 8px 8px rgba(#000000, 0.16);
        top: 0;
        border-radius: 50%;
        border-color: blue;
        z-index: 99999;
        left: 39px;
        visibility: hidden;
        opacity: 1;
        transition: 0.1s;
        p {
          display: flex;
          justify-content: space-between;
          white-space: nowrap;
          margin: 0;
          img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 0;
          }
          &:not(:first-child) {
            margin: 0 !important;
          }
          span {
            color: black;
            font-weight: 400;
          }
        }
      }
    }
    .picturePopUpContainer {
      cursor: pointer;
      &:hover {
        .picturePopUp {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding: 38px 22px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 2px solid #f0f2f5;
  }
  .backButton {
    background-color: #379fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;

    svg {
      transform: rotate(90deg);
    }
  }
  .productInfo {
    display: flex;
    align-items: center;
    padding: 13px 22px;
    border-bottom: 2px solid #f0f2f5;
    img {
      width: 32px;
      height: 32px;
      border: 1px solid #f0f2f5;
      margin-right: 13px;
    }
    .details {
      color: #252733;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
