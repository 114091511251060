@import "../../../styles/vars";
.nav {
    background-color: #fff;
    border-right: 2px solid #f0f2f5;
    display: flex;
    flex-direction: column;
    position: relative;
    .controlButton {
        align-items: center;
        background-color: #f0f2f5;
        border: 0;
        border-radius: 0 20px 20px 0;
        display: flex;
        height: 30px;
        justify-content: center;
        width: 30px;
        position: absolute;
        right: 0;
        top: 15px;
        transform: translateX(100%);
        z-index: 105;
        outline: none;
        &.controlButtonClosed {
            svg {
                transform: rotate(180deg);
            }
        }
        svg {
            transition: 0.1s;
            object-fit: contain;
        }
    }
    .navLogo {
        padding: 15px 30px;
        height: 70px;
        svg {
            display: inline-block;
            width: 94px;
            path {
                fill: #379fff;
            }
        }
        &.navLogoClosed {
            padding-inline: 8px;
            a {
                display: flex;
                justify-content: center;
            }
            svg {
                margin-top: 15px;
                margin-left: 10px;
                max-width: 100%;
            }
        }
    }
    .sidebarList {
        height: calc(100% - 200px);
        padding-top: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
            background: #bbc1c8;
            border-radius: 6px;
        }
        li {
            a,
            button {
                gap: 10px;
                cursor: pointer;
                justify-content: flex-start;
                position: relative;
                white-space: nowrap;
                display: flex;
                padding: 13px 30px;
                color: #43425d;
                text-decoration: none;
                font-weight: 600;
                border-left: 4px solid transparent;
                @media all and (max-width: 1536px) {
                    padding: 13px 20px;
                    font-size: 14px;
                }
                p {
                    margin-bottom: 0;
                }
                span {
                    display: none;
                    align-items: center;
                }
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        fill: currentColor;
                    }
                }
                &:hover {
                    background-color: #f0f2f5;
                    border-left: 4px solid #379fff;
                    color: #379fff;
                }
                &.active {
                    background-color: #f0f2f5;
                    border-left: 4px solid #379fff;
                    color: #379fff;
                }
            }
        }

        &.sidebarListClosed {
            li {
                cursor: pointer;
                a {
                    justify-content: center;
                    position: relative;
                    white-space: nowrap;
                    &:hover {
                        p {
                            visibility: visible;
                            opacity: 1;
                        }
                       
                    }
                    span {
                        z-index: 2;
                        display: flex;
                    }
                    p {
                        position: absolute;
                        height: 100%;
                        background-color: #f0f2f5;
                        color: #379fff;
                        left: 0;
                        top: 0;
                        padding: 13px 75px;
                        z-index: 1;
                        display: flex;
                        align-items: center;
                        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
                        border-radius: 0px 50px 50px 0px;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }
    .sidebarMenu {
        margin-top: auto;
        margin-bottom: 25px;

        :global {
            .HeaderUserDropDown,
            .dropdown-toggle {
                width: 100%;
                height: auto;
            }
            .sidebarUser {
                background: #ffffff;
                box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
                border-radius: 6px;
                padding: 10px;
                margin-left: 20px;
                margin-right: 20px;
                display: flex;
                gap: 10px;
                align-items: center;
                img {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 50%;
                }
                .userName {
                    overflow: hidden;
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                        color: #252733;
                        margin: 0;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .arrowIcon {
                    margin-left: auto;
                    path {
                        fill: #000;
                    }
                }
            }
            .dropdown-menu {
                left: 0;
                top: calc(-100% - 60px);
                min-width: 240px !important;
                button {
                    padding: 10px 8px;
                    font-size: 14px;
                }
            }
        }
        &.sidebarMenuClosed {
            :global {
                .sidebarUser {
                    min-height: 56px;
                    padding: 0;
                    .arrowIcon {
                        display: none;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
