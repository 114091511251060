.UplaodItem {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .uploadedItem {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            border: 0.5px solid rgba(187, 193, 200, 1);
            // max-width: 100%;
            object-fit: cover;
            border-radius: 50%;
            padding: 2px;
            width: 52px;
            height: 52px;
            min-width: 52px;
            min-height: 52px;
            max-width: 52px;
            max-height: 52px;
        }
    }
    p {
        margin-bottom: 0;
        cursor: pointer;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #377fea;
        @media all and (max-width: 767.98px) {
            color: #fff;
        }
    }
}
