.table {
  margin-top: 50px;
  .tableWrapper {
    overflow: auto hidden;
    padding-bottom: 150px;
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        tr {
          background: #f0f2f5;
          th {
            padding: 6px 7px 6px 6px;
            background: #f0f2f5;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #7e8295;

            &:last-child {
              position: sticky;
              right: 0;

            }
            &.noaccountant {
              &:nth-last-child(3),
              &:nth-last-child(2),
              &:last-child {
                position: sticky;
              }
              &:nth-last-child(3) {
                right: 260px;
                &::after {
                  background-color: #f0f2f5;
                  position: absolute;
                  top: 0;
                  bottom: -1px;
                  left: 0;
                  width: 8px;
                  transform: translateX(-100%);
                  transition: box-shadow 0.3s;
                  content: "";
                  pointer-events: none;
                  box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
                }
              }
              &:nth-last-child(2) {
                right: 160px;
              }
              &:last-child {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.allPagination {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  margin-top: 36px;
  justify-content: flex-end;
  :global {
    .pagination {
      margin-bottom: 0;
      .page-item {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          .page-link {
            font-size: 18px;
          }
        }
        .page-link {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &.active {
          .page-link {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .ProductsSeries {
    margin-left: 40px;
    display: flex;
    .ProductsSeriesItem {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 16px;
        margin-right: 10px;
      }
      .ProductsSeriesItemSelect {
        width: 70px;
        [class*="indicatorContainer"] {
          transform: rotate(180deg);
        }
      }
    }
  }
}
