@import "src/styles/vars";
.salaryRow_item {
    color: #397ae8;
}
.salaryTable {
    padding: 10px 0 25px 0;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba($black, 0.16);
    overflow: hidden;
    margin-bottom: 20px;
    background-color: rgba(239, 239, 239, 0.58);

    & > div:first-child{
        background-color: rgba(239, 239, 239, 0.34);
        box-shadow: 0 2px 4px rgba($black, 0.16);
        padding: 10px;
    }
    .salaryTableBody{
        padding: 10px 10px 0 10px;
        &:nth-child(2){
            padding: 30px 0px 0px 10px;
        }
    }
    .salaryTableRow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
        .salaryTableRow_item {
            flex: 0 0 14%;
            max-width: 14%;
            padding-left: 5px;
            padding-right: 5px;
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
            }
            p {
                margin-bottom: 0;
            }
            &.salaryTableRow_item_datePicker {
                flex: 0 0 10%;
                max-width: 10%;
            }
            &.salaryTableRow_item_salaryStatus {
                flex: 0 0 20%;
                max-width: 20%;
                p {
                    text-align: right;
                }
                .paid {
                    color: $black;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 10px;
                    }
                }
                .forPay {
                    color: $white;
                    padding: 10px 40px;
                    border: 2px solid #397ae8;
                    background-color: #73a4f8;
                }
            }
            &.salaryTableRow_item_bonus {
                input {
                    color: #000;
                    &::placeholder {
                        color: #000;
                    }
                    background-color: #f2f3f7;
                }
                .enabledInput {
                    background-color: transparent;
                    padding: 5px 7px;
                    min-width: 80px;
                    max-width: 120px;
                    display: inline-block;
                    border: 0;
                }
            }
            &:last-child {
                button {
                    margin-left: auto;
                    border-radius: 6px;
                    font-size: 16px;
                    background-color: #397ae8;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    color: $white;
                    height: 50px;
                    outline: none;
                }
            }
        }
    }
    .salaryTableBody {
        border-top: 1px solid #eaedf2;
        p {
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            transition: 0.3s;
            margin: 0;
        }
        > span {
            font-size: 18px;
            display: block;
            width: 100%;
            text-align: center;
            font-weight: 700;
        }
    }
}
