.trItem {
    &:nth-child(even) {
        background: rgba(240, 242, 245, 0.8);
    }

    .tdItem {
        padding: 18px 10px;
        &:last-child {
            position: sticky;
            background-color: #fff;
            z-index: 3;
            right: 0;
            &::after {
                background-color: #fff;
                position: absolute;
                top: 0;
                bottom: -1px;
                left: 0;
                width: 8px;
                transform: translateX(-100%);
                transition: box-shadow 0.3s;
                content: "";
                pointer-events: none;
                box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
            }
        }
        .imageWrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
            margin: 0;
            img {
                width: 34px;
                height: 34px;
                object-fit: cover;
            }
            .textItem {
                &:hover {
                    .popupName {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            .popupName {
                position: absolute;
                background-color: white;
                width: fit-content;
                min-width: 280px;
                box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.16);
                top: 0;
                left: 43px;
                border-radius: 8px;
                z-index: 99999;
                padding: 11px 20px;
                border-radius: 6px;
                visibility: hidden;
                opacity: 0;
                transition: 0.1s;
            }

            .picturePopUpContainer {
                position: relative;
                .picturePopUp {
                    position: absolute;
                    background-color: #fff;
                    width: fit-content;
                    box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
                    top: 0;
                    border-radius: 50%;
                    border-color: blue;
                    z-index: 99999;
                    left: 39px;
                    visibility: hidden;
                    opacity: 1;
                    transition: 0.1s;
                    p {
                        display: flex;
                        justify-content: space-between;
                        white-space: nowrap;
                        img {
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            object-fit: cover;
                            margin-right: 0;
                        }
                        &:not(:first-child) {
                            margin: 0 !important;
                        }
                        span {
                            color: black;
                            font-weight: 400;
                        }
                    }
                }
            }
            .picturePopUpContainer {
                cursor: pointer;
                &:hover {
                    .picturePopUp {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        p,
        button {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
        }
        .commentLine {
            text-decoration: underline;
            cursor: pointer;
        }
        .importBtn {
            background: #ffffff;
            border: 1px solid #379fff;
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #379fff;
            padding: 8px 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            height: 36px;
            svg {
                display: inline-block;
                border-right: 1px solid #e8e9ec;
                padding-right: 10px;
                margin-right: 10px;
                min-width: 30px;
                min-height: 30px;
            }
        }
        .exportBtn {
            background: #ffffff;
            border: 1px solid #ff6565;
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #ff6565;
            padding: 8px 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            height: 36px;
            svg {
                display: inline-block;
                border-right: 1px solid #e8e9ec;
                padding-right: 10px;
                margin-right: 10px;
                min-width: 27px;
                min-height: 27px;
            }
        }
        .checkBtn {
            background: #ffffff;
            border: 1px solid #252733;
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #252733;
            padding: 8px 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            height: 36px;
        }
    }
    .acceptBtn {
        background: #ffffff;
        padding: 8px 25px;
        border: 1px solid #379fff;
        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
        border-radius: 3px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #379fff !important;
        outline: none;
        margin-right: 15px !important;
    }
    .cancelBtn {
        background: #ffffff;
        padding: 8px 25px;
        border: 1px solid #ff6565;
        box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
        border-radius: 3px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #ff6565 !important;
        outline: none;
    }
}
