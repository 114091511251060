@import "../../styles/vars";
.loginPage {
    min-height: calc(100vh - 56px);
    @media all and (max-width: 767.98px) {
        min-height: calc(100vh - 80px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(359.83deg, #01ffcb -50.28%, #4068eb 116.22%);
    padding: 0 10px;
    .AuthenticationContent {
        width: 100%;
        max-width: 700px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 30px 40px;
        border-radius: 26px;
        box-shadow: 70px 30px 120px rgba($black, 0.16);
        flex-direction: column;
        @media all and (max-width: 767.98px) {
            background-color: transparent;
            box-shadow: none;
            padding-left: 5px;
            padding-right: 5px;
        }
        h1 {
            margin-bottom: 27px;
            @media all and (max-width: 767.98px) {
                svg {
                    fill: $white;
                }
            }
        }
        .goToBoardPage {
            margin-right: 40px;
            white-space: nowrap;
            padding: 12px 20px;
            border-radius: 19px;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #467fdf !important;
            background-color: rgba(#fff, 0.35);
            border: none;
            outline: none;
            transform: 180;
            width: 90px;
            cursor: pointer;
        }
        .InputParent {
            margin-bottom: 20px;
            @media all and (max-width: 767.98px) {
                margin-bottom: 13px;
            }
            input {
                height: 50px;
                width: 100%;
                border-radius: 3px;
                border: 0.5px solid $gray;
                padding-left: 22px;
                @media all and (max-width: 767.98px) {
                    height: 60px;
                    background-color: rgba($white, 0.23);
                    border: none;
                    color: $white;
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus {
                        border: 1px solid rgba($white, 0.23);
                        -webkit-text-fill-color: $white;
                        box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        -webkit-box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        transition: background-color 5000s ease-in-out 0s;
                    }
                }
                &::placeholder {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    color: $gray;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .inputPassword {
            position: relative;
            input {
                padding-right: 55px !important
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 18px;
                outline: none;
                background-color: transparent;
                border: 0;
            }
        }
        .AuthenticationBtn {
            margin-top: 43px;
            display: flex;
            width: 100%;
            button {
                display: block;
                margin-left: auto;
                background-color: $blue;
                border: none;
                border-radius: 26px;
                color: $white;
                padding: 13px 50px;
                height: 50px;
                &:focus,
                &:active {
                    background-color: darken($blue, 1);
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                }
                @media all and (max-width: 767.98px) {
                    width: 100%;
                    background-color: $white;
                    color: $black;
                    font-weight: 600;
                }
            }
            @media all and (max-width: 767.98px) {
                margin-top: 27px;
            }
        }
    }
}
