@import "../../styles/vars";
.WorkersSection {
  .workersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f0f2f5;
    margin-bottom: 35px;
    h2 {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #252733;
      padding: 25px;
      cursor: pointer;
      background-color: #f0f2f5;
    }
    .workersButton {
      background-color: #397ae8;
      color: #fff;
      padding: 9px 20px;
      border: none;
      border-radius: 3px;
      font-family: inherit;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
    .workersButton {
      @media all and (max-width: 767.98px) {
        font-size: 16px;
        margin-top: 10px;
        padding: 12px 20px;
        width: 100%;
      }
    }
  }

  .WorkersTable {
    margin-top: 40px;
    width: 100%;
    .WorkersTr {
      margin-bottom: 5px;
      td {
        padding-bottom: 10px;
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #9a99af;
        border-bottom: 1px solid #9a99af;
      }
    }
    tbody {
      td {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        font-family: inherit;
        color: #43425d;
        padding-top: 20px;
      }
      .StatusSwitchParent {
        display: flex;
        p {
          margin-bottom: 0;
          margin-right: 23px;
          line-height: 23px;
          font-size: 16px;
          color: #43425d;
          word-break: break-all;
        }
      }
      button {
        outline: none;
        border-radius: 50px;
        width: 36px;
        height: 36px;
        border: none;
        padding: 0;
        background-color: rgba(#dce0e5, 0.5);
        &:hover {
          background-color: rgba(#dce0e5, 1);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .table {
    .tableRow {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -5px;
      margin-right: -5px;
      align-items: center;

      .row_item {
        .picturePopUpContainer {
          position: relative;
          .picturePopUp {
            position: absolute;
            background-color: #fff;
            width: fit-content;
            box-shadow: 8px 8px 8px 8px rgba(#000000, 0.16);
            top: 0;
            border-radius: 50%;
            border-color: blue;
            z-index: 99999;
            left: 39px;
            visibility: hidden;
            opacity: 1;
            transition: 0.1s;
            p {
              display: flex;
              justify-content: space-between;
              white-space: nowrap;
              &:not(:first-child) {
                margin: 0 !important;
              }
              span {
                color: black;
                font-weight: 400;
              }
            }
          }
        }
        .picturePopUpContainer {
          cursor: pointer;
          &:hover {
            .picturePopUp {
              visibility: visible;
              opacity: 2;
            }
          }
        }
        p {
          font-family: inherit;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #9a99af;
          margin: 0;
        }
        padding-left: 5px;
        padding-right: 5px;
        &:first-child {
          flex: 0 0 70px;
          max-width: 70px;
          @media all and (min-width: 1681px) {
            flex: 0 0 70px;
            max-width: 70px;
          }
        }
        &:nth-child(2) {
          flex: 0 0 60px;
          max-width: 60px;
          @media all and (min-width: 1681px) {
            flex: 0 0 60px;
            max-width: 60px;
          }
        }
        &:nth-child(3) {
          flex: 0 0 170px;
          max-width: 170px;
          @media all and (min-width: 1681px) {
            flex: 0 0 210px;
            max-width: 210px;
          }
        }
        &:nth-child(4) {
          flex: 0 0 152px;
          max-width: 152px;
          @media all and (min-width: 1681px) {
            flex: 0 0 150px;
            max-width: 150px;
          }
        }
        &:nth-child(5) {
          flex: 0 0 170px;
          max-width: 170px;
          @media all and (min-width: 1681px) {
            flex: 0 0 220px;
            max-width: 220px;
          }
        }
        &:nth-child(6) {
          flex: 0 0 100px;
          max-width: 100px;
          @media all and (min-width: 1681px) {
            flex: 0 0 180px;
            max-width: 180px;
          }
        }
        &:nth-child(7) {
          flex: 0 0 220px;
          max-width: 220px;
          @media all and (min-width: 1681px) {
            flex: 0 0 280px;
            max-width: 280px;
          }
        }
        &:nth-child(8) {
          flex: 0 0 180px;
          max-width: 180px;
          @media all and (min-width: 1681px) {
            flex: 0 0 240px;
            max-width: 240px;
          }
        }
        &:nth-child(9) {
          flex: 1 1 auto;
          margin-left: auto;
        }
        &:last-child {
          flex: 1 0 auto;
        }
      }
    }
    .tableHeader {
      background-color: #f0f2f5;
      padding: 11px 13px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 3px;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        img {
          margin-left: 7px;
        }
      }
    }
    .tableBody {
      .rowItemWrapper {
        margin-bottom: 4px;
        padding: 11px 13px;
        background-color: #fff;
        &:nth-child(even) {
          background-color: rgba(240, 242, 245, 0.8);
        }
        &:hover {
          background-color: rgba(55, 127, 234, 0.0705882353);
        }
      }
      .tableRow {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .controls_btn_content {
          text-align: center;
          button {
            outline: none;
            border-radius: 50px;
            width: 36px;
            height: 36px;
            border: none;
            padding: 0;
            background-color: transparent;
            &:hover {
              background-color: rgba(220, 224, 229, 0.5);
            }
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
          color: #43425d;
          word-break: break-all;
        }
        .workersEmail {
          color: #397ae8 !important;
        }
        .worketActive {
          margin-right: 10px;
          display: inline-flex;
          width: 20px;
          height: 8px;
          border-radius: 4px;
          background-color: #397ae8;
        }
        .worketWaiting {
          margin-right: 10px;
          display: inline-flex;
          width: 20px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff7a14;
        }

        .salaryButton {
          background-color: transparent;
          color: #467fdf;
          padding: 5px;
          border: 0;
          border-radius: 50px;
          line-height: 27px;
          display: inline-flex;
          align-items: center;
          outline: none;
          font-weight: 400;
          padding: 5px 15px;
          font-size: 16px;
          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
