body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page .footer {
  margin-top: auto;
}
.layout-right {
  background-color: #fff;
}
.title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.modal-dialog {
  max-width: 580px;
}
.modal-header {
  border-bottom: 0;
}
.modal-header button {
  outline: none;
}
.modal-header .close {
  font-size: 3rem;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
}
.modal-header .close:hover {
  opacity: 1 !important;
}
.visible.higher {
  z-index: 99999 !important;
}

.staticHeader {
  margin-bottom: 48px;
  background-color: #f7f9ff;
  border-radius: 6px;
  padding: 20px 33px;
}
.staticHeader button {
  background-color: #397ae8;
  color: #fff;
  height: 50px;
  padding: 12px 43px;
  border: none;
  border-radius: 6px;
  font-family: inherit;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.switch-wrapper.customSwichItem {
  height: 20px;
  border-radius: 12px;
  overflow: visible;
}
.productpageStatusParent .switch-wrapper.customSwichItem {
  margin-left: -20px;
  margin-right: 30px;
}
.switch-wrapper.customSwichItem .switch {
  width: 100%;
  border-radius: 12px;
  left: 0 !important;
  transform: none !important;
}
.custom-switch-active:not(:disabled):not(.disabled).active:focus,
.custom-switch-active:not(:disabled):not(.disabled):active:focus,
.show > .custom-switch-active.dropdown-toggle:focus {
  box-shadow: none;
}
.custom-switch-active:not(:disabled):not(.disabled).active,
.custom-switch-active:not(:disabled):not(.disabled):active,
.show > .custom-switch-active.dropdown-toggle {
  border: none;
  background-color: #377fea;
}
.switch-wrapper.customSwichItem .custom-switch-active {
  background-color: #377fea;
}
.switch-wrapper.customSwichItem > .custom-swich-disabled {
  background-color: #dce0e5;
}
.switch-wrapper.customSwichItem > .custom-switch-active > .switch-handle {
  width: 20px;
  height: 20px;
  right: 0;
  left: calc(100% - 20px);
  top: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.switch-wrapper.customSwichItem > .custom-swich-disabled > .switch-handle {
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.css-yk16xz-control {
  border: 1px solid #a6a6ab !important;
  border-radius: 3px !important;
}
.css-g1d714-ValueContainer {
  padding-left: 0;
  padding-right: 0;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

.modal-content {
  border-radius: 12px;
  border: none;
}
div[data-name='createEditProduct'] .modal-body,
div[data-name='createEditWorker'] .modal-body {
  padding: 0;
}
div[data-name='createEditWorker'] .modal-body h1 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-top: -35px;
}
div[data-name='createEditStickers'] .stickersTitle {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-top: -53px;
}
div[data-name='createEditStickers'] .stickerFormContainer {
  margin-top: 35px;
}
.modal-btn-parent button {
  background-color: #397ae8;
  border-radius: 0 0 12px 12px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  font-family: inherit;
  height: 56px;
  color: #fff;
}
.modal-btn-parent button:focus,
.modal-btn-parent button:hover {
  border: none;
  background-color: #397ae8;
}
.modal-confimration-btns {
  width: 100%;
  display: flex;
}
.modal-confimration-btns button {
  outline: none;
}
.modal-confimration-btns button:first-child {
  margin-left: auto;
  margin-right: 10px;
  /* padding-bottom: 12px; */
}
.CreateEditContentp-37 {
  padding: 0 37px;
}
.CreateEditContentp-37 label {
  margin-top: 17px;
  font-size: 14px;
  font-family: inherit;
  color: #43425d;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  padding-bottom: 0;
}
.show-hide-modal-btn-parent button {
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #397ae8;
  font-size: 14px;
  font-weight: 600;
  margin-top: 24px;
  line-height: 19px;
  outline: none;
}
.modal-plus-btn {
  padding: 0;
  margin-top: 15px;
  border: none;
  background-color: transparent;
  color: #43425d;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.modal-x-btn {
  padding: 0;
  margin-top: 15px;
  border: none;
  background-color: transparent;
  color: #43425d;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.modal-x-btn:focus,
.modal-x-btn:active {
  outline: none;
}
.modal-x-btn span {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: hsl(0, 85%, 32%);
  margin-right: 10px;
  border-radius: 3px;
}
.modal-x-btn span svg {
  max-width: 100%;
  width: 20px;
}


.modal-plus-btn:focus,
.modal-plus-btn:active {
  outline: none;
}
.modal-plus-btn span {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #397ae8;
  margin-right: 10px;
  border-radius: 3px;
}
.modal-plus-btn span svg {
  max-width: 100%;
  width: 20px;
}
.can-remove-danger-parent {
  margin-top: 15px;
}
.can-remove-danger-parent button {
  height: 36px;
  background-color: #f3373d;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  padding: 10px 25px;
}

.modal-footer-btns {
  background-color: #dce0e5;
  display: flex;
  justify-content: flex-end;
  padding-top: 17px;
  padding-bottom: 17px;
  border-radius: 0 0 12px 12px;
}
.modal-footer-btns button {
  margin-left: 10px;
}
.btn-disabler {
  background-color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  height: 36px;
  color: #a6a6ab;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-confimer {
  background-color: #397ae8;
  font-size: 14px;
  padding: 10px 20px;
  height: 36px;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.btn-confimer:hover {
  color: #fff;
}

.editItem .lastPicker button,
.editItem .lastPicker div {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 0;
}
.editItem .lastPicker button {
  background-color: transparent !important;
}
.chrome-picker > div > div {
  z-index: 1;
}
