.trItem {
  &.product {
    .tdItem {
      padding: 5px !important;
    }
  }
  &:nth-child(even) {
    background: rgba(240, 242, 245, 0.8);
  }

  &:hover {
    background-color: rgba(55, 127, 234, 0.0705882353);
  }
  .tdItem {
    padding: 18px 10px;
    .linkToBoard {
      margin-left: 10px;
      cursor: pointer;
      a {
        background-color: #fff;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
      }
    }
    a {
      color: #252733;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &.copy {
      display: flex;
      align-items: center;
      .copyButton {
        margin-left: 10px;
      }
    }
    p {
      display: flex;
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
    }
    img {
      width: 50px;
      height: 50px;
    }
    &:hover {
      .textItem {
        button {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.moreContent {
  padding: 20px;
}

.moreButton {
  cursor: pointer;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.copied {
  padding-left: 10px;
  height: 32px;
  display: flex;
  align-items: center;
  color: gray !important;
}
