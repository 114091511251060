
.bottomMenu {
  z-index: 20;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .menuButton {
      padding: 15px;
      svg {
          fill: #bbc1c8;
      }
      &.active {
          border-bottom: 6px solid #397AE8;
          svg {
              fill: #397AE8;
          }
      }
  }
  
}
@media all and (min-width: 769px) {
  .bottomMenu {
      display: none;
  }
}