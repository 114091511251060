@import "../../styles/vars";
.notFoundPage {
    min-height: calc(100vh - 56px);
    @media all and (max-width: 767.98px) {
        min-height: calc(100vh - 80px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(359.83deg, #01ffcb -50.28%, #4068eb 116.22%);
    .notFound {
        width: 100%;
        max-width: 1000px;
        opacity: 0.9;
        font-size: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 30px 40px;
        border-radius: 26px;
        flex-direction: column;
        @media all and (max-width: 767.98px) {
            background-color: transparent;
            box-shadow: none;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}
