.adsFiltersWrapper {
    margin-top: 30px;
    input {
        outline: none;
        padding: 10px 30px 10px 10px;
        width: 100%;
        height: 40px;
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #c1cada;
        border-radius: 6px;
    }
    .searchWithButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .inputWrapper {
            flex: 0 0 360px;
        }
        button {
            outline: none;
            border: 0;
            background: #379fff;
            border-radius: 6px;
            padding: 13px 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
        }
    }
    .allfilersWrapper {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        row-gap: 22px;
        .adsRow {
            display: flex;
            gap: 10px;
            .col {
                flex: 1 1 210px;
                &:last-child {
                    align-self: flex-end;
                    flex: 0 0 48px;
                    button {
                        border-radius: 50%;
                        background-color: #fff;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        margin-left: 10px;
                        padding: 0;
                        background-color: transparent;
                        outline: none;
                        position: relative;
                        border: 0;
                    }
                }
            }
        }
        .hiddenFilterWrapper {
            border: 1px solid #d8dbe5;
            padding: 25px 20px;
        }
    }
}
