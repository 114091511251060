.modalWrapper {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background: rgba(#252733, 0.8);
  &.visible {
    z-index: 1050;
    opacity: 1;
  }
}
