.moreWrapper {
    .orderHeader {
        background-color: #eaedf2;
        padding: 10px 55px;
    }
    .bodyItem {
        padding: 12px 55px;
        border-bottom: 1px solid #eaedf2;
    }
    .orderRow {
        display: flex;
        align-items: center;
        margin-left: -15px;
        margin-right: -15px;
        .orderCol {
            padding-left: 15px;
            padding-right: 15px;
            &.orderColMain {
                flex: 0 0 536px;
                max-width: 536px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 400px;
                    max-width: 400px;
                }
            }
            &.orderColStatus {
                flex: 0 0 180px;
                max-width: 180px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 160px;
                    max-width: 160px;
                  }
            }
            &.orderColDomain {
                flex: 0 0 200px;
                max-width: 200px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 180px;
                    max-width: 180px;
                  }
            }
            &.orderColQuality {
                flex: 0 0 130px;
                max-width: 130px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 100px;
                    max-width: 100px;
                  }
            }
            &.orderColSummary {
                flex: 0 0 180px;
                max-width: 180px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 170px;
                    max-width: 170px;
                  }
            }
            &.orderColType {
                flex: 0 0 140px;
                max-width: 140px;
                @media all and (max-width: 1700px) {
                    flex: 0 0 110px;
                    max-width: 110px;
                  }
            }
            &.orderColCode {
                flex: 0 0 100px;
                max-width: 100px;
                p {
                    display: block;
                    text-align: right;
                }
            }
            p {
                display: flex;
                align-items: center;
                gap: 23px;
                font-size: 16px;
                @media all and (max-width: 1700px) {
                    font-size: 14px;
                  }
                img {
                    width: 37px;
                    height: 37px;
                    min-width: 37px;
                    min-height: 37px;
                    max-width: 37px;
                    max-height: 37px;
                    object-fit: cover;
                }
            }
        }
    }
}
