.boardNameContent {
    @media all and (max-width: 1023.98px) {
        max-width: 65%;
    }
    @media all and (max-width: 767.98px) {
        max-width: 100%;
        margin: 0;
        height: 100vh;
    }
}
.boardnNameContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    align-items: center;
    .existBoardItemContent {
        display: flex;
        padding: 0 10px;
    }
    .existBoardItem {
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #dce0e5;
        color: #000;
        min-width: 250px;
        padding: 15px;
        text-decoration: none;
        border-radius: 6px;
        @media all and (min-width: 1024px) {
            margin-bottom: 20px;
        }

        a {
            display: block;
            background-color: #dce0e5;
            color: #000;
            text-decoration: none;
            padding-right: 40px;
        }
        button {
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background-color: rgba(#ffffff, 0.5);
            font-size: 0;
            outline: none;
        }
    }
}
.existBoardItem {
    @media all and (min-width: 320px) and (max-width: 1024px) {
        width: 100%;
        background-color: #fff;
        margin-bottom: 10px;

        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        a {
            display: block;
            padding: 17px 13px;
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            color: #000;
            text-decoration: none;
        }
    }
}
