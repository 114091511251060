@import "../../styles/vars";
.loginPage {
    min-height: calc(100vh - 56px);
    @media all and (max-width: 767.98px) {
        min-height: calc(100vh - 80px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(359.83deg, #01ffcb -50.28%, #4068eb 116.22%);
    padding: 0 10px;
    .AuthenticationContent {
        width: 100%;
        max-width: 700px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 30px 40px;
        border-radius: 26px;
        box-shadow: 70px 30px 120px rgba($black, 0.16);
        flex-direction: column;
        @media all and (max-width: 767.98px) {
            background-color: transparent;
            box-shadow: none;
            padding-left: 5px;
            padding-right: 5px;
        }
        h1 {
            margin-bottom: 27px;
            @media all and (max-width: 767.98px) {
                svg {
                    fill: $white;
                }
            }
        }
        .InputParent {
            margin-bottom: 20px;
            @media all and (max-width: 767.98px) {
                margin-bottom: 13px;
            }
            input {
                height: 50px;
                width: 100%;
                border-radius: 3px;
                border: 0.5px solid $gray;
                padding-left: 22px;
                @media all and (max-width: 767.98px) {
                    height: 60px;
                    background-color: rgba($white, 0.23);
                    border: none;
                    color: $white !important;
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus {
                        border: 1px solid rgba($white, 0.23);
                        -webkit-text-fill-color: $white;
                        box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        -webkit-box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        transition: background-color 5000s ease-in-out 0s;
                    }
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-text-fill-color: #000 !important;
                        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                    }
                }
                &::placeholder {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    color: $gray;
                }
                &:focus {
                    outline: none;
                }
            }
            &.InputParentWithText {
                position: relative;
                input {
                    padding-right: 230px;
                    @media only screen and (max-width: 767px) {
                        padding-right: 10px;
                    }
                }
                a {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    margin: 0;
                    @media only screen and (max-width: 767px) {
                        position: static;
                        display: block;
                        text-align: right;
                        margin-top: 5px;
                        transform: none;
                    }
                }
            }
        }
        .inputPassword {
            position: relative;
            input {
                padding-right: 55px !important
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 18px;
                outline: none;
                background-color: transparent;
                border: 0;
            }
        }
        .AuthenticationBtn {
            margin-top: 43px;
            display: flex;
            width: 100%;
            button {
                display: block;
                margin-left: auto;
                background-color: $blue;
                border: none;
                border-radius: 26px;
                color: $white;
                padding: 13px 50px;
                height: 50px;
                &:focus,
                &:active {
                    background-color: darken($blue, 1);
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                }
                @media all and (max-width: 767.98px) {
                    width: 100%;
                    background-color: $white;
                    color: $black;
                    font-weight: 600;
                }
            }
            @media all and (max-width: 767.98px) {
                margin-top: 27px;
            }
        }
        .rememberForgot {
            display: flex;
            justify-content: space-between;
            align-items: center;
            :global {
                .rememberMe {
                    display: flex;
                    align-items: center;
                }
            }
            input {
                width: 16px;
                height: 16px;
            }
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #8f8e9a;
            }
            a {
                white-space: nowrap;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-decoration-line: underline;

                color: #3581e5;
            }
        }
    }
}
