.table {
  margin-top: 50px;
  padding: 10px 0;
  .tableWrapper {
    overflow: auto hidden;
    padding-bottom: 130px;
    &::-webkit-scrollbar {
      width: 2px;
    }
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        tr {
          background: #f0f2f5;
          th {
            &:last-child {
              background: #f0f2f5;
              position: sticky;
              z-index: 3;
              right: 0;
            }
            padding: 11px 13px;
            background: #f0f2f5;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #7e8295;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            background: rgba(240, 242, 245, 0.8);
          }
          &:hover {
            background-color: rgba(55, 127, 234, 0.0705882353);
          }
        }
        td {
          padding: 5px;
          .imageWrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #252733;
            margin: 0;
            img {
              width: 34px;
              height: 34px;
              object-fit: cover;
            }

            .picturePopUpContainer {
              position: relative;
              .picturePopUp {
                position: absolute;
                background-color: #fff;
                width: fit-content;
                box-shadow: 2px 2px 8px 8px rgba(#000000, 0.16);
                top: 0;
                border-radius: 50%;
                border-color: blue;
                z-index: 99999;
                left: 39px;
                visibility: hidden;
                opacity: 1;
                transition: 0.1s;
                p {
                  display: flex;
                  justify-content: space-between;
                  white-space: nowrap;
                  margin: 0;
                  img {
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 0;
                  }
                  &:not(:first-child) {
                    margin: 0 !important;
                  }
                  span {
                    color: black;
                    font-weight: 400;
                  }
                }
              }
            }
            .picturePopUpContainer {
              cursor: pointer;
              &:hover {
                .picturePopUp {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
        td {
          padding: 11px 13px;
          .goToButton {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          &:last-child {
            background: #fff;
            position: sticky;
            z-index: 3;
            padding-left: 10px;
            right: 0;
            &::after {
              background-color: #fff;
              position: absolute;
              top: 0;
              bottom: -1px;
              left: 0;
              width: 8px;
              transform: translateX(-100%);
              transition: box-shadow 0.3s;
              content: "";
              pointer-events: none;
              box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
            }
          }
        }
      }
    }
  }
}

.allPagination {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  margin-top: 36px;
  justify-content: flex-end;
  :global {
    .pagination {
      margin-bottom: 0;
      .page-item {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          .page-link {
            font-size: 18px;
          }
        }
        .page-link {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &.active {
          .page-link {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .ProductsSeries {
    margin-left: 40px;
    display: flex;
    .ProductsSeriesItem {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 16px;
        margin-right: 10px;
      }
      .ProductsSeriesItemSelect {
        width: 70px;
        [class*="indicatorContainer"] {
          transform: rotate(180deg);
        }
      }
    }
  }
}
