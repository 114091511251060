.copyItem {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #252733;
    &:hover {
        button {
            visibility: visible;
            opacity: 1;
        }
    }
    &.copied {
        color: #379fff;
        button {
            svg {
                path {
                    fill: #379fff;
                }
            }
        }
    }
    button {
        visibility: hidden;
        opacity: 0;
        padding: 0;
        background-color: transparent;
        border: 0;
        outline: none;
    }
}
