.optionsList {
    background-color: #fff;
    box-shadow: 0 3px 18px rgba(#000, 0.3);
    margin-bottom: 0;
    > li {
        padding: 14px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        font-family: "Nunito Sans", sans-serif;
        &:hover {
            background-color: #F2F4F9;
        }
        svg {
            margin-right: 17px;
        }
    }
}