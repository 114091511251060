.cardsContent {
    padding: 17px;
    @media all and (max-width: 768px) {
      margin: 0 0 72px;
      width: 100%;
    }
    .title {
      margin-bottom: 15px;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    .card {
      background-color: #d0e8ff33;
      width: 252px;
      border-radius: 6px;
      margin-right: 20px;
      margin-bottom: 20px;
      text-decoration: none;
      padding: 12px 14px;
  
      @media all and (max-width: 768px) {
        width: 100%;
      }
      @media all and (max-width: 1371px) and (min-width: 1099px) {
        width: calc(33% - 20px);
      }
      @media all and (max-width: 1098px) and (min-width: 769px) {
        width: calc(50% - 20px);
      }
  
      &:hover {
        background-color: #d0e8ff77;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          color: #252733;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
  
        .percent {
          &.negative {
            background-color: #fff5ec;
            span {
              color: #e7281c;
            }
          }
          background-color: #ecfff1;
          border-radius: 9px;
          padding: 0 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin-right: 2px;
          }
          span {
            color: #11af80;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
      .amount {
        color: #252733;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        margin: 20px 0;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        .bottomItem {
          color: #686c82;
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
  }
  