@import '../../styles/vars';
.loginPage {
    min-height: calc(100vh - 56px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(359.83deg, #01ffcb -50.28%, #4068eb 116.22%);
    @media all and (max-width: 767.98px) {
        min-height: calc(100vh - 80px);
    }
    .AuthenticationContent {
        width: 100%;
        max-width: 700px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 30px 40px;
        border-radius: 26px;
        box-shadow: 70px 30px 120px rgba($black, 0.16);
        flex-direction: column;
        @media all and (max-width: 767.98px) {
            box-shadow: none;
            background-color: transparent;
            padding-left: 20px;
            padding-right: 20px;
            .colWithoutadding {
                padding: 0;
            }
        }
        h1 {
            margin-bottom: 27px;
            @media all and (max-width: 767.98px) {
                margin-bottom: 0;
            }
            svg {
                @media all and (max-width: 767.98px) {
                    fill: $white;
                }
            }
        }
        .workerRegP {
            color: #43425d;
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            margin-bottom: 8px;
            @media all and (max-width: 767.98px) {
                color: $white;
                text-align: center;
                margin-bottom: 30px;
            }
        }
        .InputParent {
            margin-bottom: 20px;
            @media all and (max-width: 767.98px) {
                margin-bottom: 13px;
            }
            input {
                height: 50px;
                width: 100%;
                border-radius: 3px;
                border: 0.5px solid $gray;
                padding-left: 22px;
                @media all and (max-width: 767.98px) {
                    height: 60px;
                    background-color: rgba($white, 0.23);
                    border: none;
                    color: $white;
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus {
                        border: 1px solid rgba($white, 0.23);
                        -webkit-text-fill-color: $white;
                        box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        -webkit-box-shadow: 0 0 0px 1000px rgba($white, 0.23) inset;
                        transition: background-color 5000s ease-in-out 0s;
                    }
                }
                &::placeholder {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    color: $gray;
                }
                &:focus {
                    outline: none;
                }
                &:disabled {
                    background-color: #F7F9FF;
                    border: none;
                    @media all and (max-width: 767.98px) {
                        background-color: #397AE8;
                    }
                }
            }
        }
        .AuthenticationBtn {
            margin-top: 20px;
            display: flex;
            width: 100%;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                background-color: $blue;
                border: none;
                border-radius: 26px;
                color: $white;
                padding: 13px 50px;
                height: 50px;
                font-weight: 600;
                &:focus, &:active {
                    background-color: darken($blue, 1);
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                }
                @media all and (max-width: 767.98px) {
                    width: 100%;
                    background-color: $white;
                    color: $black;
                    font-weight: 600;
                    height: 66px;
                    border-radius: 33px;
                }
            }
        }
        .inputPassword {
            position: relative;
            input {
                padding-right: 55px !important
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 18px;
                outline: none;
                background-color: transparent;
                border: 0;
            }
        }
    }
}