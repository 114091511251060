@import "../../src/styles/vars";

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif !important;
  background: #fff !important;

  @media all and (min-width: 1024px) {
    -ms-overflow-style: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  p {
    font-family: "Roboto", sans-serif !important;
  }
}

iframe {
  display: none;
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}
.count-total-price {
  position: relative;
  .currency {
    position: absolute;
    right: 15px !important;
    top: 12px;
    border: none;
    outline: none !important;
    background-color: transparent;
    height: 40px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.color-picker {
  &.position-absolute {
    z-index: 2;
  }
}

.my-20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

@media all and (max-width: 767.98px) {
  .mt-20-mob {
    margin-top: 20px;
  }

  .mb-20-mob {
    margin-bottom: 20px;
  }
}

.maxw-100 {
  max-width: 100%;
}

.bg-lightblue {
  background-color: #f1f6fc;
}

.text-transparent {
  color: transparent;
  user-select: none;
  z-index: -9999;
  position: relative;
}

.text-red {
  color: #ff0000 !important;
  font-size: 12px !important;
  margin-bottom: 0;
}

.rememberMe {
  display: inline-block;
  width: 100%;
}
.rememberCheck {
  width: 40px !important;
  cursor: pointer;
}
.headerPart {
  padding: 15px 30px;
  height: 70px;
  position: fixed;
  top: 0;
  left: 260px;
  z-index: 999;
  width: calc(100% - 260px);
  border-bottom: 2px solid #f0f2f5;

  @media all and (max-width: 1536px) {
    width: calc(100% - 210px);
    left: 210px !important;
  }

  background: #fff;
  align-items: center;

  @media all and (max-width: 767.98px) {
    padding: 15px !important;
    left: 0 !important;
  }

  @media all and (min-width: 320px) and (max-width: 1024px) {
    left: 0;
    width: 100%;
  }

  .settings {
    .HeaderUserDropDown {
      width: 33px;
      height: 33px;

      .dropdown-toggle {
        border-radius: 50%;
        overflow: hidden;

        @media all and (max-width: 767.98px) {
          margin-right: 10px;
        }

        width: 33px;
        height: 33px;

        img {
          width: 33px;
          height: 33px;
        }
      }

      .userSettingDropdwon {
        button {
          font-size: 14px;
        }
      }
    }

    @media all and (max-width: 767.98px) {
      display: none;
    }
  }

  .dropdown-menu-right {
    padding: 0 !important;

    button {
      &:first-child {
        border-radius: 6px 6px 0 0 !important;
      }

      &:last-child {
        border-radius: 0 0 6px 6px !important;
      }
    }
  }

  &.BoardPage {
    width: 100%;
    left: 0 !important;
    padding: 13px 39px 13px 19px !important;

    .houseLinkParent {
      margin-right: 150px !important;

      @media all and (max-width: 767.98px) {
        margin-right: 0 !important;
      }

      a {
        @media all and (max-width: 767.98px) {
          display: flex;

          svg {
            height: 16px;

            path {
              fill: #fff !important;
            }
          }
        }

        svg {
          path {
            fill: #43425d;
          }
        }
      }
    }

    span {
      &.headerSpan {
        color: #43425d !important;

        @media all and (max-width: 767.98px) {
          color: #fff !important;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .filterIconParent {
      svg {
        g {
          path {
            fill: #43425d;
          }
        }
      }
    }
  }

  &.archive_page {
    background: #43425d !important;

    .houseLinkParent {
      a {
        svg {
          path {
            fill: #fff;
          }
        }
      }

      span {
        &.headerSpan {
          color: #fff !important;
        }
      }
    }

    .filterIconParent {
      svg {
        g {
          path {
            fill: #fff;
          }
        }
      }
    }

    .goToBoardPage {
      margin-right: 40px;
      white-space: nowrap;
      padding: 12px 20px;
      border-radius: 19px;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: rgba(#fff, 0.35);
      border: none;
      outline: none;

      @media only screen and (max-width: 767.98px) {
        font-size: 12px;
        padding: 5px 10px;
        margin-left: 5px;
        margin-right: 5px;
      }

      svg {
        transform: rotate(180deg);
        margin-right: 10px;
        width: 11px;
        height: 18px;

        @media only screen and (max-width: 767.98px) {
          width: 5px;
        }

        path {
          fill: #fff;
        }
      }
    }
  }

  .headerSpan {
    color: #43425d;
    font-size: 18px;
    font-weight: 600;
  }

  .filterIconParent {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @media all and (max-width: 767.98px) {
      width: 35px;
      height: 35px;
    }

    svg {
      width: 20px;

      @media all and (max-width: 767.98px) {
        width: auto;
        height: 16px;
      }
    }

    &.activeFilter {
      box-shadow: 0 3px 6px rgba(#000, 0.16);

      @media all and (max-width: 767.98px) {
        background-color: rgba(#fff, 0.2);
      }
    }
  }
  &.all-pages_header {
    display: none !important;
  }
}

.ox-auto {
  overflow-x: auto;
}

.draggbleContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.userSettingDropdwon {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
}
div {
  &[data-name="importProduct"],
  &[data-name="exportProduct"],
  &[data-name="checkProduct"] {
    .modal-header {
      background: #f0f2f5;
      border-radius: 12px 12px 0px 0px;
      font-family: "Noto Sans Armenian";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #252733;
      padding-left: 46px;
    }
    .modal-dialog {
      max-width: 1499px !important;
    }
  }
  &[data-name="createEditOrder"] {
    .modal-dialog {
      max-width: 1379px !important;
    }
  }
}

.main-page {
  &.inBoardMain {
    @media only screen and (max-width: 767.98px) {
      background-color: #eaedf2;
    }
  }

  .layoutSidebar {
    @media all and (min-width: 320px) and (max-width: 768px) {
      position: fixed;
      left: -100%;
    }

    @media all and (max-width: 1536px) {
      width: 210px !important;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    z-index: 21;
    &.layoutSidebarClosed {
      @media all and (max-width: 1536px) {
        width: 80px !important;
      }
      width: 80px !important;
    }
  }

  .layoutRightbar {
    @media all and (min-width: 320px) and (max-width: 1024px) {
      width: 100% !important;
      background-color: #eaedf2;
      padding: 0 15px !important;
      margin-left: 0 !important;
    }

    @media all and (max-width: 1536px) {
      margin-left: 230px !important;
      padding: 0 10px !important;
      width: calc(100% - 230px) !important;

      &.inBoardPage {
        margin-left: 0 !important;
      }
    }

    width: calc(100% - 260px) !important;
    margin-left: 260px;
    @media all and (max-width: 767px) {
      padding: 0 10px !important;
      margin-left: 0 !important;
      width: 100% !important;
    }

    &.notAuthenticated {
      padding: 0 !important;
      width: 100% !important;
    }
    &.layoutRightbarClosed {
      margin-left: 100px !important;
      width: calc(100% - 100px) !important;
      &.inBoardPage {
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
  }

  @media all and (max-width: 767.98px) {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
}

div {
  &[data-name="createBoard"],
  &[data-name="createEditBoard"],
  &[data-name="createEditAddress"],
  [data-name="editUserProfileSettings"] {
    .modal-body {
      padding: 0;
    }
  }
}

.createBoardFullModalContent {
  padding: 33px 40px;
  padding-top: 0;

  label {
    font-size: 14px;
    color: #43425d;
    margin-bottom: 7px;
    font-weight: 700;
  }

  .css-1wa3eu0-placeholder {
    padding-left: 17px;
  }

  .createboardInputParent {
    display: flex;
    row-gap: 26px;
    flex-direction: column;
    input,
    select {
      width: 100%;
      display: block;
      margin-bottom: 0;
      height: 36px;
      border-radius: 3px;
      border: 1px solid #a6a6ab;
      padding-left: 17px;

      &:focus {
        outline: none;
        border: 1px solid #397ae8;
      }
    }
  }
  [class*="-ValueContainer"] {
    overflow: initial;
  }
  [class*="-Input"] {
    position: static;
  }
}

.modalFullBtn {
  margin-top: 20px;
  width: 100%;
  padding: 14px 0;
  border-radius: 0 0 12px 12px;
  background: #397ae8;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none !important;

  &:hover {
    color: #fff;
  }
  &.modalArchiveButton {
    margin-top: 14px;
    border-radius: 3px;
    height: 50px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.766' height='21.766' viewBox='0 0 21.766 21.766'%3E%3Cpath d='M7.814,27.766A1.86,1.86,0,0,1,6,25.952V10.142a2.838,2.838,0,0,1,.091-.771,1.506,1.506,0,0,1,.333-.589l1.693-2.3a1.287,1.287,0,0,1,.559-.378A2.345,2.345,0,0,1,9.416,6H24.35a2.2,2.2,0,0,1,.726.106,1.3,1.3,0,0,1,.544.378l1.723,2.3a1.506,1.506,0,0,1,.333.589,2.838,2.838,0,0,1,.091.771v15.81a1.86,1.86,0,0,1-1.814,1.814ZM8.328,9.2h17.08L24.319,7.814H9.416Zm-.514,1.814V25.952H25.952V11.018Zm9.069,12.213L21.6,18.515l-1.209-1.209-2.6,2.6V13.83H15.976v6.076l-2.6-2.6-1.209,1.209ZM7.814,25.952h0Z' transform='translate(-6 -6)' fill='%23364364'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-position: 10px;
    background-color: #fff;
    padding-left: 45px;
    border: 1px solid #cbd2de;
    font-size: 14px;
    font-weight: 500;
    justify-content: flex-start;
    color: #364364;
  }
}

div {
  &[data-name="createEditOrder"] {
    > div {
      &:first-child {
        width: 100%;
        > div {
          &:first-child {
            width: 100%;
          }
        }
      }
    }
    .modal-dialog {
      max-width: 100% !important;
      margin: 0;

      @media only screen and (max-width: 767.98px) {
        width: unset;
        max-width: 100% !important;
        overflow-x: hidden;
      }

      .modal-content {
        border-radius: 0 !important;

        .modal-inner-container {
          pointer-events: none !important;

          &.editable,
          &.editable * {
            pointer-events: initial !important;
          }
        }
        .create-edit-container {
          max-width: 1420px;
          overflow: hidden;
          @media all and (max-width: 1700px) {
            padding-top: 65px;
          }
        }
      }

      .bg-lightblue {
        padding-bottom: 58px;
      }

      .close {
        position: absolute;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background-color: #f2f4f9;
        border: 0;
        top: 17px;
        left: 31px;
        padding: 8px;
        outline: none !important;
        z-index: 999;
        span {
          display: flex;
        }
        @media all and (max-width: 767.98px) {
          top: 10px !important;
          left: 10px !important;
          padding: 0;
          width: 35px;
          height: 35px;
          svg {
            display: block;
            width: 100% !important;
            height: 100% !important;
          }
        }
        &:hover {
          svg {
            line {
              stroke: #000;
            }
          }
        }
        svg {
          width: 20px;
          height: 20px;
          line {
            stroke: #a1acbf;
          }
        }
      }

      .editAccess {
        position: absolute;
        top: 17px;
        right: 55px;
        z-index: 1;
        font-size: 18px;
        font-weight: 700;
        color: rgba(21, 188, 0, 0.68);
      }

      .modal-header {
        display: none;
      }

      .close {
        @media all and (max-width: 767.98px) {
          top: 0;
          right: 15px;
          padding-right: 0;
          opacity: 1;
          font-weight: 400;
        }
      }

      .edit {
        padding-right: 80px;
        margin-right: 80px;
        min-width: 116px;
        @media all and (max-width: 767.98px) {
          top: 30px;
          right: 80px;
          margin-right: 80px;
          padding-right: 0;
          opacity: 1;
          font-weight: 400;

          span {
            color: #fff;
          }
        }
      }

      .editAccess {
        padding-right: 0px;
        margin-right: 0px;

        @media all and (max-width: 767.98px) {
          top: 30px;
          right: 0px;
          margin-right: 80px;
          padding-right: 0;
          opacity: 1;
          font-weight: 400;

          span {
            color: #fff;
          }
        }
      }

      .modal-body {
        padding: 0;

        ._modal_title {
          margin-top: 0;
          padding-left: 0;
        }

        .mobile-bg-gray {
          @media all and (max-width: 767.98px) {
            border-radius: 0;
          }
        }

        .createEditLeftSide {
          padding-left: 0;
          padding-right: 10px;

          @media all and (max-width: 767.98px) {
            padding: 0;
            margin-top: 60px;
          }

          ._modal_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .modal-change_btn {
              button.edit {
                margin: 0;
                background-color: transparent;
                color: #000;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 22px 10px;
                padding-right: 0;
                border-radius: 6px;
                height: 36px;
                font-size: 14px;
                line-height: 600;
                cursor: pointer;
                position: absolute;
                right: 0;
                z-index: 1;
                pointer-events: all;
                top: 50%;
                transform: translateY(-50%);
                outline: none;

                &.active {
                  background-color: #cbd2de;
                }

                span {
                  margin-right: -10px;

                  svg {
                    height: 40px;
                    width: 40px;
                  }
                }
              }
              &.archive_btn {
                button {
                  background-color: #397ae8 !important;
                  padding-right: 10px;
                  color: #fff;
                }
              }
            }
          }

          .createEditOrderItem {
            .row {
              margin-left: -5px;
              margin-right: -5px;

              [class*="col-"] {
                padding-right: 5px;
                padding-left: 5px;
              }
            }
            [class*="-Input"] {
              display: flex;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              max-width: 100%;
              height: 48px;
            }
          }

          &.createEditLeftSideBottom {
            padding-right: 0;

            p {
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;

              &.createEditLeftSideBottomPrice {
                font-size: 22px;
                font-weight: 700;

                @media only screen and (max-width: 767.98px) {
                  text-align: center !important;
                }
              }

              &.createEditLeftSideBottomText {
                color: #000;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 16px;
                margin-top: 32px;
                &:not(.text-red) {
                  padding: 15px 16px;
                  background-color: #f2f4f9;
                  justify-content: space-between;
                }
                @media only screen and (max-width: 767.98px) {
                  flex-direction: column;
                  justify-content: center;
                }
              }
            }

            @media all and (max-width: 767.98px) {
              text-align: center;
            }
          }
        }

        .createEditRightSide {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 41px;
          padding-bottom: 60px;

          @media all and (max-width: 767.98px) {
            padding-right: 0;
            padding-top: 0;
            margin-top: 0;
            padding-left: 0;
          }

          .rightside_custom_row {
            margin-left: -4px;
            margin-right: -4px;

            div[class*="col-"] {
              padding-left: 4px;
              padding-right: 4px;

              .createEditOrderItem {
                margin-top: 14px;
              }
            }
          }

          div[class*="-menu"] {
            div[class*="-MenuList"] {
              padding-top: 0;
              padding-bottom: 0;
              margin-bottom: 0;

              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 6px;
              }

              &::-webkit-scrollbar-thumb {
                background: #bbc1c8;
                border-radius: 6px;
              }
            }
          }

          .createOrderLinkAndDate {
            position: relative;
            z-index: 1;
            pointer-events: all;
            display: flex;
            align-items: center;

            a {
              display: flex;
              align-items: center;
              margin-right: 15px;

              svg {
                margin-right: 5px;
              }
            }

            span {
              font-size: 14px;
              font-weight: 600;
            }
          }
          .cloneLink {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 14px;
            gap: 18px;
            box-shadow: 0 3px 6px rgba(#000, 0.16);
            height: 50px;
            button {
              height: 30px;
              width: 25px;
              border: 0;
              background-color: transparent;
              background-repeat: no-repeat;
              background-size: contain;
              outline: none;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.4' height='24' viewBox='0 0 20.4 24'%3E%3Cpath d='M7.8,27.95A1.846,1.846,0,0,1,6,26.15V8.06H7.8V26.15H22.02v1.8Zm3.6-3.6a1.846,1.846,0,0,1-1.8-1.8V5.75a1.727,1.727,0,0,1,.54-1.26,1.727,1.727,0,0,1,1.26-.54H24.6a1.727,1.727,0,0,1,1.26.54,1.727,1.727,0,0,1,.54,1.26v16.8a1.846,1.846,0,0,1-1.8,1.8Zm0-1.8H24.6V5.75H11.4Zm0,0v0Z' transform='translate(-6 -3.95)' fill='%23397ae8'/%3E%3C/svg%3E");
            }
            a {
              color: #364364;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .header_link_archive {
            display: flex;
            align-items: center;
            gap: 18px;

            > div {
              flex: 1;

              &:first-child {
                flex: 1 0 auto;
              }
              &:last-child {
                flex: 0 1 50%;
              }
            }
          }
          .date_of_link {
            margin-top: 8px;
          }
          .emojiWrapper {
            padding: 0 11px;
            margin-top: 22px;
            margin-bottom: 30px;
            > h2 {
              font-size: 12px;
              margin-bottom: 10px;
            }
            .emojiItem {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: space-between;
              background-color: #fff;
              padding: 12px 11px;
              margin-bottom: 14px;
              box-shadow: 0 3px 6px rgba(#000, 0.16);
              border-radius: 6px;
              font-size: 14px;
            }
          }
          .emojiItemWrapper {
            position: relative;

            p {
              position: absolute;
              top: -45px;
              left: 0;
              visibility: hidden;
              opacity: 0;
              white-space: nowrap;
              padding: 3px 10px;
              font-size: 14px;
              font-weight: 600;
              text-align: center;
              background-color: #fff;
              box-shadow: 0 3px 6px rgba(#000, 0.16);
              transition: 0.3s;
              border-radius: 3px;
              &::after {
                content: "";
                position: absolute;
                left: 3px;
                bottom: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8.7px 5px 0 5px;
                border-color: #fff transparent transparent transparent;
              }
            }
            button {
              transition: 0.3s;
              &:hover {
                transform: translateY(-8px) rotate(-15deg);
                + p {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
          .emojiList {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            align-items: center;
            gap: 9px;
            margin-right: 30px;
            p {
              margin: 0 12px 0;
            }
            button {
              outline: none;
              padding: 0;
              background-color: transparent;
              border: 0;
            }
          }
        }

        input,
        select {
          &:not([type="radio"]) {
            height: 50px !important;
            border-radius: 3px;
            outline: none;
            border: 0.5px solid #cbd2de;
            padding-left: 17px;

            &:focus {
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
              border: 1px solid #397ae8;
            }

            @media all and (max-width: 767.98px) {
              &:not([type="radio"]) {
                height: 50px !important;
              }

              background-color: $white;
            }
          }
          &:disabled {
            border-color: transparent;
            background-color: #cbd2de;
          }
        }

        .label {
          white-space: nowrap;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          margin-bottom: 11px;
          color: #43425d;
        }

        [class*="-ValueContainer"] {
          height: 48px !important;
          border-radius: 3px;
          outline: none;
          padding-left: 17px;
          position: initial;
          font-size: 14px;

          &:focus {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            border: 1px solid #397ae8;
          }

          @media all and (max-width: 767.98px) {
            padding-left: 4px;
            padding-right: 4px;
          }
        }

        .addOtherProductBtn {
          border: none;
          background-color: transparent;
          padding: 0;
          display: flex;
          align-items: center;
          line-height: 19px;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          color: #397ae8;

          span {
            margin-right: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            line-height: 35px;
            color: #fff;
            background-color: #397ae8;
            width: 30px;
            height: 30px;
            border-radius: 3px;
          }

          @media all and (max-width: 767.98px) {
            margin-top: 0;
          }
        }
      }

      .createEditBtns {
        @media all and (max-width: 767.98px) {
          margin-top: 20px !important;
        }
      }

      .CreateEditBtn {
        margin-bottom: 40px;
        margin-top: 50px;
        border-radius: 6px;
        background-color: #397ae8;
        color: #fff;
        border: none;
        padding: 15px 30px;
        line-height: 30px;
        font-weight: 600;
        font-size: 18px;
        outline: none;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        @media all and (max-width: 767.98px) {
          margin-left: 0;
          width: 100%;
          height: 50px;
          margin-top: 20px;
        }
        &.CreateEditBtnChange {
          margin-top: 0;
        }
      }
    }

    .new-added-prod {
      margin-top: 15px;
      padding: 15px;
      border-radius: 15px;
      background-color: #f1f6fc;

      .productTypeSelect {
        [class*="-menu"] {
          min-width: 100%;
          left: 0;

          @media all and (max-width: 1440px) {
            min-width: 750px;
          }

          @media all and (max-width: 1200px) {
            min-width: 600px;
          }

          @media all and (max-width: 767.98px) {
            right: auto;
            left: 0;
            min-width: unset;
          }
        }

        div[class*="-menu"] {
          div[class*="-MenuList"] {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;

            &::-webkit-scrollbar {
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              background: #fff;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #bbc1c8;
              border-radius: 6px;
            }

            // &::-webkit-scrollbar-thumb:hover {
            //     background: #555;
            // }
          }
        }
      }

      .custom-row {
        margin-left: -20px;
        margin-right: -20px;

        .custom-col-110 {
          padding-left: 20px;
          padding-right: 20px;

          input {
            font-size: 14px;
          }
        }
      }
    }

    .createEditOrderItem {
      margin-top: 30px;

      .row {
        margin-left: -5px;
        margin-right: -5px;

        [class*="col-"] {
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      .paymetnCode {
        label {
          display: block;
        }

        p {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #43425d;

          span {
            display: block;
            border-radius: 3px;
            padding: 12px 7px;
            letter-spacing: 5px;
            background-color: #dce0e5;
            text-transform: uppercase;
          }

          button {
            margin-top: 5px;
            background-color: transparent;
            padding: 0;
            border: 0;
            outline: none;
          }
        }

        &.productInnerPaymentConde {
          .alertText {
            display: none;
            &.visible {
              display: block;
            }
          }
        }
      }

      .topCloneItem {
        p {
          margin-bottom: 0;
          button {
            margin: 0;
            margin-top: -10px;
            box-shadow: none;
          }
        }
      }

      .createEditRadioBtns {
        .row {
          margin-left: -7px;
          margin-right: -7px;

          > .col {
            padding-left: 7px;
            padding-right: 7px;
          }
        }
      }
    }
    .topCloneItemText {
      color: green;
      visibility: hidden;
      opacity: 0;
      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .createEditReturnChangeComponent {
      width: 100%;
      position: absolute;
      padding-bottom: 30px;
      left: 0;
      top: calc(100% + 10px);
    }
  }
}

div {
  &[data-name="createEditOrder"] {
    h1 {
      padding-left: 47px;
      @media all and (max-width: 767.98px) {
        font-size: 16px !important;
        button {
          right: 15px !important;
        }
      }
    }
  }
}
.withSeparator {
  position: relative;
  input {
    color: transparent !important;
    &:-webkit-autofill {
      color: transparent !important;
      -webkit-text-fill-color: transparent !important;
      background-color: #fff !important;
    }
    &:autofill {
      color: transparent !important;
      -webkit-text-fill-color: transparent !important;
      background-color: #fff !important;
    }
  }
  .viewedValue {
    position: absolute;
    background-color: transparent;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.inputWithButton {
  position: relative;
  input {
    padding-right: 35px;
  }
}
.currency {
  position: absolute;
  right: 15px !important;
  top: 12px;
  border: none;
  outline: none !important;
  background-color: transparent;
  height: 40px;
}
.searchButton {
  position: absolute;
  right: 10px !important;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none !important;
  background-color: transparent;
}

._modal_title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-top: -35px;
  overflow: hidden;
  height: 50px;

  @media all and (max-width: 767.98px) {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    background-color: #397ae8;
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 15px !important;
    color: #fff;
  }
}

.radiLabelItem {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  .checkMarkItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #dce0e5;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkMarkItem {
    &::after {
      content: "";
      position: absolute;
      display: none;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 11;

    &:checked {
      ~ .checkMarkItem {
        background-color: #397ae8;

        &::after {
          display: block;
        }
      }
    }

    &:disabled {
      ~ .checkMarkItem {
        background-color: #f7f7f7;
      }
    }
  }

  &:hover {
    input {
      ~ .checkMarkItem {
        background-color: #ccc;
      }

      &:checked {
        ~ .checkMarkItem {
          background-color: #397ae8;
        }
      }

      &:disabled {
        cursor: default;

        ~ .checkMarkItem {
          cursor: default;
          background-color: #f7f7f7;
        }
      }
    }
  }
}

//! DRAGGABLE CONTENT
.layoutRightbar {
  .BoardControlArrow {
    width: 120px;
    height: 120px;
    position: fixed;
    right: 0;
    top: 50%;
    background-color: #fff;
    transform: translateY(-50%);
    z-index: 10;
    align-items: center;
    justify-content: center;
    right: -60px;
    border-radius: 50%;
    display: none;

    @media all and (min-width: 1024px) {
      display: flex;
    }

    svg {
      margin-right: 40px;
    }

    &.leftControl {
      right: auto;
      left: -60px;

      svg {
        transform: rotate(180deg);
        margin-right: 0;
        margin-left: 40px;
      }
    }
  }
}

.draggbleContainer {
  padding-top: 100px;
  height: calc(100vh - 21px);

  .draggableFormCreatParent {
    margin-left: 20px;
    flex: 0 0 320px;
    padding-right: 42px;

    .draggableFormCreat {
      width: 100%;
      height: 52px;
      border: none;
      padding: 13px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #397ae8;
      color: #fff;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }

    form {
      background: #dde2e9;
      border-radius: 6px;
      padding: 13px;

      input {
        width: 100%;
        height: 40px;
        border-radius: 3px;
        border: 1px solid #397ae8;
        outline: none;
        margin-bottom: 10px;
        padding: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;

        &::placeholder {
          font-weight: 400;
        }
      }

      button {
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        height: 30px;
        border: none;

        &:first-child {
          margin-right: 3px;
          width: 30px;
          font-size: 30px;

          background-color: #fff;
          color: #a6a6ab;
        }

        &:last-child {
          font-size: 14px;
          background-color: #397ae8;
          color: #fff;
          height: 30px;
          line-height: 19px;
          font-weight: 600;
          padding: 5px 20px;
        }
      }
    }
  }
}

.draggableItem {
  height: 100%;
  padding-bottom: 10px;

  .columnParent {
    .shadow {
      min-width: 100vw;
      height: 100vh;
      background-color: rgba(#383843, 0.8);
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 100;
    }
  }

  .column {
    padding: 0 3px;
    width: 358px;
    display: flex;
    flex-direction: column;
    margin: unset;
    border: none;
    height: 100%;
    background-color: transparent;
    position: relative;
    margin: 0 7px;
    background-color: #dde2e9;
    border-radius: 3px;

    .editMenu {
      background-color: rgba(153, 205, 50, 0);
      position: absolute;
      width: 140px;
      display: flex;
      top: 0px;
      left: 360px;
      z-index: 1000000;

      .dropdownList {
        margin-bottom: 0;
        border: none;
        z-index: 9999;

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          text-shadow: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          margin-bottom: 10px;
          font-family: sans-serif;
          padding: 8px 12px;
          border-radius: 3px;
          transition: 0.3s;
          width: 240px;
          color: #43425d;

          span {
            padding-right: 16px;

            svg {
              width: 19px;
              height: 19px;
            }
          }

          background-color: rgba(#fff, 0.8);

          &:hover {
            margin-left: 16px;
            background-color: rgba(#fff, 1);
          }
        }
      }
    }

    .column-title {
      text-shadow: none;
      border-radius: 3px;
      background-color: #397ae8;
      color: #fff;
      padding: 13px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      align-items: center;
      position: relative;
      margin: 0 -3px;
      flex-wrap: wrap;
      height: 60px;

      .total_price {
        margin: 0;
        flex: 0 0 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
        color: rgba(#fff, 0.7);
      }

      .dropdown-menu {
        background-color: #fff;
        border-radius: 3px;
        padding: 0;
        box-shadow: 0 3px 6px rgba(#000, 0.16);
        z-index: 9999;

        .dropdownList {
          margin-bottom: 0;
          border: none;
          background-color: #fff;
          border-radius: 10px;
          padding: 7px 10px;

          .title {
            display: flex;
            border-bottom: 1px solid #dce0e5;
            margin-bottom: 10px;
            font-size: 12px;
            color: rgba(#000, 0.5);
            padding-bottom: 10px;

            .closeButton {
              margin-left: auto;
              cursor: pointer;
            }
          }

          li {
            display: block;
            cursor: pointer;
            text-shadow: none;
            color: #43425d;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 16px;
          }
        }
      }
    }

    .columnPrice {
      font-size: 28px;
      font-family: 400px;
      line-height: 38px;
      margin-bottom: 0;
    }

    .height-preserving-container {
      margin-bottom: 6px !important;
    }

    .item {
      background-color: #fff;
      position: relative;
      left: unset !important;
      text-align: left;
      justify-content: flex-start;
      padding: 13px 8px 8px 13px;
      margin: 0 13px;
      border-radius: 3px;

      .ListiTemDropdown {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .itemEdit {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #edeff2;
        border-radius: 50%;

        > div {
          font-size: 0;
        }
      }

      // overflow: visible;
      .dropdownList {
        margin-bottom: 0;
        border: none;
        position: absolute;
        right: -20px;
        z-index: 9999;

        li {
          display: block;
          cursor: pointer;
          text-shadow: none;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 20px;
          font-family: sans-serif;
          background-color: yellow;
          padding: 15px;
          border-radius: 5px;
          transition: 0.3s;

          &:hover {
            left: 20px;
            background-color: red;
          }
        }
      }
    }

    button {
      margin-top: auto;
      outline: none;
    }

    > div {
      margin-top: 10px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bbc1c8;
        border-radius: 6px;
      }

      // &::-webkit-scrollbar-thumb:hover {
      //     background: #555;
      // }
    }

    .ColumnHeaderDropdwon {
      .dropdown-toggle {
        transform: rotate(90deg);
      }
    }
  }

  .draggableItemTitleDots {
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    align-items: center;
    position: relative;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
    }

    &::after {
      top: 10px;
    }

    &::before {
      top: -10px;
    }
  }
}

.is-dragging {
  &.orderItem {
    box-shadow: none !important;
    background: #fff !important;
    text-align: left !important;
    justify-content: flex-start !important;
    padding: 13px 8px 8px 13px !important;
    margin-right: 10px !important;

    .itemEdit {
      width: 28px !important;
      height: 28px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      background: #edeff2 !important;
      border-radius: 50% !important;

      > div {
        font-size: 0 !important;
      }
    }
  }
}

.orderItem {
  border: none !important;
  min-height: 50px;

  .dropdown-menu {
    background-color: none;

    &.dropdown-menu-right {
      top: -55%;
      right: -8px;
    }
  }

  .itemEditButton {
    @media all and (max-width: 767.98px) {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover {
    .itemEditButton {
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdown {
    .itemEdit {
      opacity: 0;
      visibility: hidden;
    }
  }

  &:hover {
    .dropdown {
      .itemEdit {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.addOrderBtn {
  min-height: 34px;
  height: 34px;
  border-radius: 3px;
  background-color: #fff;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #43425d;
  margin-left: 12px;
  width: calc(100% - 36px);

  &.AfterButtonHasNot {
    margin-bottom: 10px;
  }
}

//! DRAGGABLE CONTENT END
.HoursMinutesContainer {
  input {
    outline: none;
    width: 40px !important;
    padding: 0 !important;
    text-align: center;
    display: flex !important;
    justify-content: center !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  position: relative;
}
.px-1 {
  &:first-child {
    .HoursMinutesContainer {
      &::after {
        content: "";
        width: 16px;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 50%;
        right: -22px;
      }
    }
  }
}

.stickerItemContent {
  &:last-child {
    > div {
      border-bottom: 1px solid transparent;
    }
  }
}

//!edit User Profile Settings
div {
  &[data-name="editUserProfileSettings"] {
    .modal-dialog {
      max-width: 700px !important;
    }
  }
}

//!edit User Profile Settings end

// !CUSTOM COL
.custom-row {
  margin: 0 -5px;

  .col-3 {
    padding: 0 5px;
    max-width: 25%;
    flex: 0 0 25%;

    @media all and (max-width: 767.98px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.custom-col-110 {
  padding: 0 5px;
  max-width: 110px;
  flex: 0 0 110px;

  @media all and (max-width: 767.98px) {
    max-width: 100%;
    flex: 0 0 100%;
  }

  input {
    padding-left: 9px !important;
  }
}

.custom-col-20 {
  padding: 0 5px;
  max-width: 20%;
  flex: 0 0 20%;

  @media all and (max-width: 767.98px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

// !CUSTOM COL END
// !PRODCUT TYPE SELECT
.productTypeSelect {
  [class*="ValueContainer"] {
    [class*="singleValue"] {
      max-width: calc(100% - 40px);
      z-index: 1;
      overflow: initial;
    }
  }

  [class*="-menu"] {
    min-width: 100%;
    right: 0;

    @media all and (max-width: 1440px) {
      min-width: 720px;
    }

    @media all and (max-width: 1200px) {
      min-width: 600px;
    }

    @media all and (max-width: 767.98px) {
      right: auto;
      left: 0;
      min-width: unset;

      .productTypeSelectOption {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
    .modal_product_type_name_code_box_icon {
      position: relative;
      width: 28px;
      height: 28px;
      display: flex;
      span {
        margin-top: 2px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    [class*="-MenuList"] {
      > div {
        padding: 0 13px 0;

        &:first-child {
          padding-top: 13px;
        }

        border-radius: 3px;
        cursor: pointer;

        img {
          border-radius: 50%;
          object-fit: cover;
        }

        margin-bottom: 5px;

        .css-9gakcf-option {
          cursor: pointer;
          position: relative;
          padding-right: 37px;
          padding-top: 5px;
          padding-bottom: 5px;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            background-image: url("../images/check-gray.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 15px;
            height: 11px;
          }

          label,
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            color: #43425d;
            margin: 0;
          }
        }

        > div {
          cursor: pointer;
          background-color: transparent;
          padding-right: 17px;

          @media all and (max-width: 767.98px) {
            padding-right: 0;
            padding-left: 0;
          }

          border-radius: 3px;
          padding-top: 5px;
          padding-bottom: 5px;

          &:hover {
            background-color: #eaedf2;
          }

          label,
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            color: #43425d;
            margin: 0;
          }
        }
      }
    }
  }

  .modal_product_type_name {
    cursor: pointer;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    padding-right: 25px;

    img {
      margin-right: 23px;
    }

    @media all and (max-width: 1200px) {
      flex: 0 0 50%;

      img {
        margin-right: 10px;
      }
    }

    @media all and (max-width: 767.98px) {
      flex: 0 0 100%;

      img {
        margin-right: 10px;
      }
    }

    @media all and (max-width: 992px) {
      label {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }

    @media all and (max-width: 767.98px) {
      label {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
    }

    img,
    label,
    span {
      cursor: pointer;
    }
  }

  .modal_product_type_status {
    cursor: pointer;
    flex: 0 0 20%;

    @media all and (max-width: 1200px) {
      flex: 0 0 25%;
    }

    @media all and (max-width: 767.98px) {
      margin-top: 8px;
    }

    img,
    label,
    span {
      cursor: pointer;
    }
  }

  .modal_product_type_name_code {
    cursor: pointer;
    flex: 0 0 20%;

    @media all and (max-width: 1200px) {
      flex: 0 0 25%;
    }

    img,
    label,
    span {
      cursor: pointer;
    }
  }
  .modal_product_type_box {
    cursor: pointer;
    flex: 0 0 10%;

    @media all and (max-width: 1200px) {
      flex: 0 0 25%;
    }

    img,
    label,
    span {
      cursor: pointer;
    }
    > span {
      display: flex;
      justify-content: flex-end;
    }
  }
}

// !PRODCUT TYPE SELECT END

// !Sticker SELECT
.stickerSelectContainer {
  div[class*="-menu"] {
    padding: 22px 20px;
  }

  .stickerColoredItemMenu {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 6px;
    border-radius: 3px;

    > div {
      cursor: pointer;
      background-color: unset;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        display: none;
      }

      &:hover {
        background-color: initial;
      }

      &.css-9gakcf-option {
        svg {
          display: block !important;
        }
      }
    }

    label {
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.stickerCheckedItems {
  margin-top: 10px;
  min-width: 90px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    margin: 0;
    border-radius: 3px;
    padding: 5px 25px;
    font-size: 14px;
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
}

// !Sticker SELECT END

// !Workers SELECT
.workersSelect {
  div[class*="-MenuList"] {
    padding: 22px 18px !important;
  }

  .select__option {
    cursor: pointer;
    border: 1px solid #eaedf2;
    border-radius: 3px;
    margin-bottom: 6px;
    position: relative;
    padding: 10px 30px 10px 10px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: #43425d;
    background-color: transparent !important;

    &::after {
      content: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      right: 8px;
      background-image: url("../images/check-gray.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover {
      box-shadow: 0 3px 6px rgba(#000, 0.16);
    }
  }

  div[class*="select__option--is-selected"] {
    box-shadow: 0 3px 6px rgba(#000, 0.16);

    &::after {
      content: "";
    }
  }
}

// !Workers SELECT END
.status_after_content {
  padding-right: 20px;

  @media only screen and (max-width: 767.98px) {
    padding-right: 0;
  }

  .statuc_icon_text {
    padding-bottom: 27px;

    span {
      padding-right: 11px;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}

.showMore {
  background: none;
  border: none;
  color: #0750cc;
  text-decoration: underline;
  cursor: pointer;
}

.returnChangeButtons {
  display: flex;
  width: 100%;
  padding-top: 50px;

  @media only screen and (max-width: 767.98px) {
    flex-direction: column;
    align-items: flex-start !important;

    button {
      width: 100%;
      margin-left: 0 !important;
    }
  }

  > span {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    margin-left: 8px;
  }

  .statuc_icon_text {
    padding-left: 32px;

    @media only screen and (max-width: 767.98px) {
      padding-left: 0;
      margin-bottom: 15px;
    }

    span {
      padding-right: 11px;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 18px 36px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 3px;
    border: none;
    outline: none;

    &:first-of-type {
      margin-left: auto;
      margin-right: 6px;
      background-color: #ff7478;
      color: #fff;
    }

    &:last-of-type {
      background-color: #dce0e5;
      color: #43425d;
    }

    @media all and (max-width: 767.98px) {
      text-align: center;
      justify-content: center;
      height: 40px;
    }
  }

  &.hasShowMore {
    * {
      font-size: 14px !important;
    }

    .statuc_icon_text {
      padding-left: 20px;
    }

    .returnChangeButtons_btn {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px !important;
    }
  }
}

.bottomBnsContainer {
  margin-top: 42px;
  padding-bottom: 30px;

  @media all and (max-width: 767.98px) {
    padding-bottom: 0;
  }
}

.bottomBlueBTnContainer {
  margin-top: 42px;

  @media all and (max-width: 767.98px) {
    margin-top: 0;
    padding-top: 20px;
    background-color: #eaedf2;
    text-align: center;

    .createEditLeftSideBottomText {
      margin-bottom: 19px !important;
      font-size: 10px !important;
    }

    .createEditLeftSideBottomPrice {
      margin-bottom: 26px !important;
    }

    .createEditBtnParent {
      flex: 0 0 auto;
      button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-size: 18px !important;
        height: 60px;
        padding: 5px 20px;
        border-radius: 6px !important;
      }
    }
  }
}

// !CD STYLES
.CdContainer {
  @media all and (max-width: 767.98px) {
    width: 100%;
    padding: 0 5px;
  }

  p {
    &.label {
      padding-left: 2px;
    }
  }

  .CdRow {
    margin-right: 5px;

    .CdCol {
      margin-left: auto;
      padding-left: 2px;
      padding-right: 0;
      flex: 0 0 100%;
      max-width: 28px;

      input {
        padding: 0 !important;
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
        padding-right: 0 !important;
        width: 100% !important;
        font-size: 14px;
        font-weight: 400;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
          -moz-appearance: textfield;
          appearance: textfield;
        }

        &:disabled {
          background-color: #dce0e5;
          border-color: #dce0e5 !important;
        }
      }

      @media all and (max-width: 767.98px) {
        max-width: 27px;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

// !CD STYLES END

// !CHECKBOX STYLES
.checkboxParent {
  > div {
    background-color: transparent !important;
  }
}

.chechBoxItem {
  color: black;
  display: block;
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  .checkMarkItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #dce0e5;
  }

  .checkMarkItem {
    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 47%;
      width: 8px;
      height: 13px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg) translateX(-50%);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;

    &:checked {
      ~ .checkMarkItem {
        background-color: #397ae8;

        &::after {
          display: block;
        }
      }
    }

    &:disabled {
      ~ .checkMarkItem {
        background-color: #f7f7f7;
      }
    }
  }

  &:hover {
    input {
      ~ .checkMarkItem {
        background-color: #ccc;
      }

      &:checked {
        ~ .checkMarkItem {
          background-color: #397ae8;
        }
      }

      &:disabled {
        cursor: default;

        ~ .checkMarkItem {
          cursor: default;
          background-color: #f7f7f7;
        }
      }
    }
  }
}

// !CHECKBOX STYLES END

// !ARMPOST MODAL
div {
  &[data-name="createEditCD"] {
    .modal-header {
      padding-bottom: 0;
    }

    .modal-body {
      padding: 0;

      .CdModalContent {
        padding: 0 31px 43px;

        p {
          &.label {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            transform: translateY(-15px);
          }
        }

        .CdRow {
          margin: 0;
          margin-top: 43px;

          @media all and (max-width: 767.98px) {
            margin-top: 20px;
            justify-content: center;
          }

          .CdCol {
            padding: 6px 0;
            background-color: #dce0e5;

            &:not(:first-child) {
              input {
                border-left: 1px solid #a6a6ab;
              }
            }

            padding-right: 0;
            padding-left: 0;
            flex: 0 0 100%;
            max-width: 43px;

            @media all and (max-width: 767.98px) {
              max-width: 24px;

              input {
                font-size: 17px;
              }
            }

            input {
              border: none;
              background-color: transparent !important;
              padding: 0 !important;
              text-align: center !important;
              display: flex !important;
              justify-content: center !important;
              padding-right: 0 !important;
              width: 100% !important;
              font-size: 20px;
              font-weight: 600;
              color: #bbc1c8;
              outline: none;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                -moz-appearance: textfield;
                appearance: textfield;
              }

              &:disabled {
                color: #43425d;
              }
            }
          }
        }

        .chechBoxItem {
          padding-left: 25px;
        }
      }
    }
  }
}

div {
  &[data-name="downLoadCSF"] {
    .modal-header {
      padding-bottom: 0;
    }

    .modal-body {
      padding: 0;

      .CdModalContainer {
        .CdModalContent {
          padding: 0 31px 43px;
          p {
            &.label {
              margin-bottom: 23px;
              font-size: 20px;
              font-weight: 600;
              transform: translateY(-15px);
            }
          }

          .chechBoxItem {
            margin-bottom: 23px;

            &:first-child {
              margin-right: 50px !important;
            }
          }
        }
      }
    }
  }
}

// !ARMPOST MODAL END
div {
  &[data-name="notification"] {
    .modal-header {
      button {
        font-size: 2rem !important;
      }
    }
  }

  .modal-dialog {
    max-width: 768px !important;

    .videoNotificationText {
      color: #000;

      font-weight: 700;

      &.closeText {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}

.returnChangeContainer {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  @media only screen and (max-width: 767.98px) {
    border-radius: 0;
  }

  .returnChangeHeder {
    background-color: #eaedf2;
    padding: 4px 29px;

    @media only screen and (max-width: 767.98px) {
      padding: 4px 11px;
    }
  }

  .selectInputParent {
    margin-top: 20px;
    padding: 0 29px;

    [class*="-control"] {
      height: 40px;
      border-radius: 6px !important;
    }
  }

  @media all and (max-width: 767.98px) {
    background-color: #eaedf2;
    margin-top: 0;
  }

  .returnChangeRadios {
    padding: 15px 10px;

    @media all and (max-width: 767.98px) {
      background-color: #fff;
      margin-bottom: 10px;
      padding: 14px 11px !important;
      border-radius: 3px;

      label {
        padding-top: 3px;
        font-size: 12px;
      }

      &:first-child {
        margin-top: 10px;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    label {
      margin-bottom: 0 !important;
    }

    @media all and (max-width: 767.98px) {
      padding-left: 0;
    }

    .radiLabelItem {
      input:checked {
        ~ .checkMarkItem {
          background-color: #397ae8;
        }
      }

      .checkMarkItem {
        background-color: #fff;

        @media only screen and (max-width: 767.98px) {
          background-color: #dce0e5;
        }
      }
    }
  }
}

// !Return Modal Card
.returnChangeTitle {
  font-size: 18px;
  color: #43425d;
  line-height: 24px;
  margin-bottom: 28px;
  text-transform: capitalize;
}

.allContainerTable {
  padding: 22px 38px;

  .tableHeader {
    > div {
      padding: 0 5px;
    }

    .table_ProductName {
      p {
        padding-left: 30px;
      }
    }

    p {
      font-size: 12px;
      color: #bbc1c8;
      line-height: 16px;
      font-weight: 400;
    }
  }

  .tableContent {
    > div {
      padding: 0 5px;
    }
    .tableProductPrice {
      .table_innnerItem {
        p {
          margin-bottom: 0;
        }
      }
    }
    .tableProductReturnPrice,
    .table_ProductChangeDifferencey {
      input {
        border: none !important;
        background: #eaedf2;
        color: #43425d !important;
        font-size: 16px !important;
        line-height: 21px !important;
      }
    }

    align-items: center;
    margin-bottom: 10px;

    .table_innnerItem {
      .productTypeSelect {
        [class*="ValueContainer"] {
          [class*="singleValue"] {
            max-width: calc(100% - 40px);
            z-index: 1;
          }
        }

        [class*="menu"] {
          min-width: 770px;
          right: 0;

          @media all and (max-width: 1440px) {
            min-width: 750px;
          }

          @media all and (max-width: 1200px) {
            min-width: 600px;
          }

          @media all and (max-width: 767.98px) {
            right: auto;
            left: 0;
            min-width: unset;
          }
        }
      }

      .innerText {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        color: #43425d;
        margin: 0;
        white-space: normal;
      }

      .innerCounter {
        button {
          border: none;
          background-color: #eaedf2;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 0;
          outline: none;
          font-size: 20px;
          line-height: 27px;
          font-weight: 600;
        }

        span {
          display: block;
          padding: 0 5px;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }
      }

      input {
        width: 100%;
        padding-left: 10px !important;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: #a6a6ab;
      }
    }

    .minusClass {
      color: #f3373d;
    }

    .plusClass {
      color: #397ae8;
    }
  }
}

.returnChangeBtns {
  margin-left: auto;
  margin-top: 40px;
  display: flex;
  padding: 22px 29px 22px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 0.5px;
    background-color: #bbc1c8;
  }

  @media all and (max-width: 767.98px) {
    padding-bottom: 20px;
  }

  button {
    outline: none;
    padding: 10px 70px;
    margin-left: 10px;
    display: flex;
    height: 60px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    @media all and (max-width: 767.98px) {
      height: 40px;
      padding: 10px 15px;
    }

    &:first-child {
      margin-left: auto;
      background-color: transparent;
      color: #43425d;
      border: none;
      font-weight: 400;
      padding-right: 20px;
    }

    &:last-child {
      font-weight: 600;
      background-color: #397ae8;
      color: #fff;
      border: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

// !Return Modal Card End
.addProductBoardBtn {
  border: none;
  background-color: transparent;
  margin-top: 10px !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #43425d;

  &:last-child {
    min-height: 34px;
    height: 34px;
    border-radius: 3px;
    border: none;
    outline: none;
    box-shadow: 0 3px 6px rgba(#000, 0.16);
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 10px 12px;
    width: calc(100% - 36px);
    background-color: rgba(66, 124, 222, 0.88);
    color: #fff;
  }
}
.downloadBoardBtn {
  border: none;
  font-weight: 600;
  min-height: 34px;
  height: 34px;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 10px 0 10px 12px;
  width: calc(100% - 36px);
  background-color: #285bb7;
  color: #fff;
  svg {
    height: 13px;
    width: 18px;
  }
}

.moveBoardBtn {
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  min-height: 34px;
  height: 34px;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 12px;
  width: calc(100% - 36px);
  background-color: rgba(39, 109, 210, 0.88);
  position: relative;
  svg {
    height: 13px;
    width: 18px;
    transform: rotate(-90deg);
  }
  span {
    visibility: hidden;
    z-index: -1;
    max-width: 0;
  }
  &.toggle {
    &:hover {
      span {
        visibility: visible;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background-color: #fff;
        width: 100%;
        max-width: 100%;
        padding: 5px 10px;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
        transition: 0.1s;
        font-size: 12px;
        white-space: nowrap;
        color: #000;
        z-index: 1;
      }
    }
  }
}

div {
  &[data-name="attachDeliverymenProduct"],
  &[data-name="stateProducts"] {
    .bg-milkwhite {
      background-color: #f3f3f3;
      border-radius: 0 12px 12px 0;
    }

    .modal-dialog {
      max-width: 1080px !important;

      .modal-header {
        display: none;
      }

      .modal-body {
        padding: 0;

        .orderProductInnerTitle {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: #43425d;
          margin-bottom: 20px;
        }

        .colInnerItem {
          padding: 28px 30px;

          .closeAttachModal {
            display: block;
            position: absolute;
            cursor: pointer;
            top: 7px;
            right: 14px;
          }

          &:first-child {
            padding-right: 15px;
          }

          .rightSideContent {
            margin-top: 10px;

            p {
              margin-bottom: 16px;
              color: #43425d;

              &.rightSideTopItemTitle {
                font-size: 14px;
                line-height: 19px;
                font-weight: 400;
                display: flex;
                align-items: center;
              }

              &.countLinkItem {
                a {
                  font-size: 18px;
                  line-height: 24px;
                  font-weight: 600;
                  text-decoration: underline;
                }
              }

              &.rightSideTopItemPrice {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                text-align: right;
              }
            }

            .rightSideBtnContent {
              .rightSideBtn {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 6px;
                border: none;
                outline: none;
                color: #a6a6ab;
                background-color: transparent;
                padding: 0;
                font-size: 15px;
                opacity: 1;
              }

              &:first-child {
                .rightSideBtn {
                  font-weight: 600;
                  margin-bottom: 14px;
                  padding: 10px 20px;
                  background-color: #dce0e5;
                  opacity: 0.5;
                  color: #43425d;

                  &:hover {
                    opacity: 1;
                  }
                }
              }

              &:last-child {
                .rightSideBtn {
                  font-weight: 600;
                  margin-bottom: 14px;
                  padding: 10px 20px;
                  background-color: #397ae8;
                  opacity: 0.5;
                  color: #fff;
                  opacity: 1;
                }
              }
            }
          }

          .leftSideContent {
            .headerTable {
              p {
                font-size: 12px;
                color: #43425d;
                line-height: 16px;
                margin-bottom: 0;
              }
            }
            .addProductWrapItem {
              &:last-of-type,
              &:nth-last-of-type(2) {
                .customRow {
                  .customColInnerItem {
                    &.customColInnerItemBig {
                      .bigImage {
                        top: calc(-100% - 40px);
                      }
                    }
                  }
                }
              }
            }
            .customRow {
              margin-bottom: 15px;

              .customCol {
                padding: 0 15px;
                position: relative;

                &.customCol-big {
                  flex: 0 0 220px;
                  max-width: 220px;
                }
                &.customCol-biged {
                  flex: 0 0 260px;
                  max-width: 260px;
                }

                &.customCol-sameSize {
                  flex: 0 0 116px;
                  max-width: 116px;
                  text-align: center;
                }
              }

              .customColInnerItem {
                &.sizedItem {
                  margin: auto;
                  width: 40px;
                  height: 40px;
                  text-align: center;
                  background-color: #dce0e5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 2px;

                  svg {
                    display: none;
                  }

                  p {
                    font-size: 12px;
                    color: #43425d;
                    line-height: 16px;
                    margin-bottom: 0;
                  }

                  input {
                    font-size: 12px;
                    color: #43425d;
                    height: 40px;
                    width: 100%;
                    text-align: center;
                    outline: none;
                    border: 1px solid #bbc1c8;
                    border-radius: 2px;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                      -moz-appearance: textfield;
                      appearance: textfield;
                    }
                  }

                  &.zeroItem {
                    padding: 5px;
                    border: 1px solid #ff7478;
                    background-color: transparent;

                    p {
                      font-size: 12px;
                      color: #bbc1c8;
                      line-height: 16px;
                      margin-bottom: 0;
                    }
                  }

                  &.notZeroItem {
                    border: 1px solid transparent;
                    background-color: #ff7478;

                    p {
                      color: #fff;
                      font-size: 12px;
                      line-height: 16px;
                      margin-bottom: 0;
                    }
                  }
                }

                &.customColInnerItemBig {
                  p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19px;
                    margin-bottom: 0;
                    color: #43425d;
                  }
                  .bigImage {
                    position: absolute;
                    background-color: #fff;
                    z-index: 1;
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                    overflow: hidden;
                    top: 20px;
                    right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    img {
                      width: 100% !important;
                      height: 100% !important;
                      object-fit: cover;
                      margin: 0;
                    }
                  }
                  img:hover ~ .bigImage {
                    display: flex;
                  }
                }
              }

              .innerSearchItem {
                position: relative;

                svg {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 10px;
                }

                input {
                  outline: none;
                  height: 30px;
                  border: 1px solid #bbc1c8;
                  border-radius: 2px;
                  padding-right: 25px;
                  padding-left: 8px;
                  width: 100%;
                }
              }
            }

            .contentTable {
              overflow-y: auto;
              overflow-x: hidden;
              max-height: 400px;
              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 6px;
              }

              &::-webkit-scrollbar-thumb {
                background: #bbc1c8;
                border-radius: 6px;
              }

              // &::-webkit-scrollbar-thumb:hover {
              //     background: #555;
              // }
              .addProductWrapItem {
                padding: 8px 0;
                display: flex;
                margin-bottom: 10px;
                flex-direction: column;
                justify-content: center;
                .customRow {
                  margin-bottom: 0;
                }
                &:hover {
                  background-color: rgba(55, 127, 234, 0.0705882353);
                }
              }
            }

            .addProductTabelLoadMore {
              button {
                background-color: transparent;
                border: 0;
                color: #397ae8;
                text-decoration: underline;
                outline: none;
              }
            }

            .footerTable {
              margin-top: 25px;
              border-top: 1px solid #dce0e5;
              padding-top: 5px;

              .customColInnerItemBig {
                p {
                  font-weight: 400 !important;
                }
              }

              .sizedItem {
                width: 100% !important;
                background-color: transparent !important;

                p {
                  font-size: 14px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

div {
  &[data-name="attachDeliverymenProduct"] {
    > div {
      &:first-child {
        height: 100%;
        .show {
          height: 100%;
        }
      }
    }
    .modal-dialog {
      max-width: 100% !important;
      width: 100% !important;
      margin: 0;
      height: 100%;
      .modal-content {
        height: 100%;
        border-radius: 0;
        .bg-milkwhite {
          border-radius: 0;
        }
      }
    }
  }
}
.carItemColor {
  flex-wrap: wrap;

  p {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    min-height: 20px;
    border-radius: 2px;
    margin-bottom: 0;
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.WorkersSection_global {
  .container {
    max-width: 100%;
  }
}

// !HEADER SEARCH
.mainSearchParentContainer {
  @media only screen and (max-width: 767.98px) {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  &.open {
    border-radius: 20px 20px 0 0;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
    background-color: #fff;

    @media only screen and (max-width: 767.98px) {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.headerSearchBigContainer {
  border-radius: 20px;
  width: 696px !important;
  max-width: 100%;
  padding: 1px;
  background-color: #f5f5f5;
  position: relative;

  @media all and (max-width: 767.98px) {
    padding: 0;
    border-radius: 0;
    width: auto !important;
    background-color: #fff;
  }

  &.open {
    border-radius: 20px 20px;
  }

  .searcHwithOptionsParent {
    min-width: 98px !important;

    @media only screen and (max-width: 767.98px) {
      min-width: unset !important;
    }
  }

  .unpositionForSearch {
    position: static;
  }

  .options-wrapper {
    border-right: 1px solid #a6a6ab;
    padding-left: 20px;

    @media all and (max-width: 767.98px) {
      padding-left: 0 !important;
      border: none;
    }

    .wrapperBigSelect {
      @media only screen and (max-width: 767.98px) {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eaedf2;
      }

      > div[class*="-control"] {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent;

        + {
          div[class*="-menu"] {
            left: -21px;
            top: 82%;
            box-shadow: none !important;
            border-radius: 0 0 20px 20px;
            width: 696px;

            @media all and (max-width: 767.98px) {
              width: 340px;
              left: -5px;
              border-radius: 0;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
            }

            div[class*="-MenuList"] {
              @media only screen and (max-width: 767.98px) {
                overflow-x: hidden;
              }
            }
          }
        }

        @media all and (max-width: 767.98px) {
          > div[class*="-ValueContainer"] {
            padding: 2px 4px;
            display: none;

            > div[class*="-placeholder"] {
              font-size: 12px;
              white-space: nowrap;
            }

            + div[class*="-IndicatorsContainer"] {
              > div[class*="-indicatorContainer"] {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .headerSearchItemSelected {
      display: flex;
      align-items: center;

      .selectedIcon {
        display: block;
        width: 23px;
        height: 23px;
        background-color: #dce0e5;
        border-radius: 50%;
        margin-right: 6px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #fff;
          visibility: hidden;
          opacity: 0;
        }
      }

      .selectionText {
        margin-bottom: 0;

        @media all and (max-width: 767.98px) {
          font-size: 12px;
        }
      }

      &.active {
        .selectedIcon {
          background-color: #397ae8;

          &::after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .checkboxParent {
      .headerSearchOPenedInnerContainer {
        padding: 15px;

        @media only screen and (max-width: 767.98px) {
          padding: 5px;
        }

        .headerSearchSelectParent {
          @media only screen and (max-width: 767.98px) {
            overflow-x: hidden;
          }

          div[class*="-MenuList"] {
            &::-webkit-scrollbar {
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              background: #fff;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #bbc1c8;
              border-radius: 6px;
            }
          }

          @media all and (max-width: 767.98px) {
            margin-left: auto;
            max-width: 92%;

            div[class*="-ValueContainer"] {
              > div[class*="-placeholder"] {
                font-size: 12px;
                white-space: nowrap;
              }

              + div[class*="-IndicatorsContainer"] {
                > div[class*="-indicatorContainer"] {
                  padding: 0;
                }
              }
            }
          }

          div[class*="-control"] {
            border: 0.5px solid #cbd2de !important;
            border-radius: 3px !important;
            box-shadow: none !important;

            div[class*="-ValueContainer"] {
              flex-wrap: nowrap;
            }
          }
        }
      }

      &:not(:last-child) {
        .headerSearchOPenedInnerContainer {
          border-bottom: 1px solid #dce0e5;
        }
      }
    }
  }

  .headerSearchInput {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    input {
      background-color: transparent;
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      padding-right: 10px;
      padding-left: 10px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      outline: none;
      height: 100%;
      border: none;
      padding: 0;
      background-color: transparent;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    @media all and (max-width: 767.98px) {
      background-color: #f1f1f1;
      border-radius: 20px;

      input {
        transition: width 0.3s;
        background-color: #f1f1f1;
        border-radius: 20px 0 0 20px;
      }

      &.SearchMobileEvents {
        input {
          padding-left: 10px;
          transition: width 0.3s;
          width: 100%;
        }
      }
    }
  }

  .searchResultOpened {
    position: absolute;
    left: 0;
    width: 696px;
    border-radius: 0 0 20px 20px;
    background-color: #fff;
    box-shadow: -1px 2px 3px rgba(74, 74, 74, 0.16);

    @media all and (max-width: 767.98px) {
      width: 375px;
      left: -180px;
    }

    .searchDraggableItem {
      margin-bottom: 12px;
      align-items: flex-start;

      .draggableItem {
        padding-bottom: 0;
        height: initial;
        border-radius: 6px;
        border: 1px solid #dce0e5;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        cursor: pointer;

        .column {
          margin: 0;
          width: 100%;
          padding: 0;

          .orderItem {
            margin-left: 0;
            margin-right: 0;
            padding: 10px 14px;

            .dropdown {
              display: none;
            }

            .contentTitle {
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #43425d;
            }
          }
        }
      }
    }

    .resultContent {
      padding: 14px 9px;
      max-height: 600px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
        padding-right: 15px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
        border: 16px solid transparent;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-thumb {
        background: #bbc1c8;
        border-radius: 20px;
        border: px solid transparent;
        background-clip: padding-box;
      }
    }

    .loadMoreBtn {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 20px 20px;
      background-color: #dce0e5;
      border: none;
      color: #397ae8;
      padding: 15px 30px;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      outline: none;
    }

    .searchResultItemName {
      margin: 0;
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: #43425d;
    }

    .searchResultItemDescr {
      font-size: 12px;
      font-weight: 400;
      color: #43425d;
      margin-bottom: 0;

      span {
        font-weight: 600;
        color: #397ae8;
      }
    }
  }
}

div[data-name="createEditOrder"] {
  .modal-dialog {
    .modal-body {
      .custom_col_7 {
        flex: 0 0 56.339%;
        max-width: 56.339%;

        @media only screen and (max-width: 767.98px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .custom_col_5 {
        flex: 0 0 43.661%;
        max-width: 43.661%;

        @media only screen and (max-width: 767.98px) {
          flex: 0 0 100%;
          max-width: 100%;
          background-color: #fff;
        }
      }

      .returnMobileContainer {
        background-color: $white;
        margin-bottom: 20px;
        padding: 20px 10px;

        .oneColDiv {
          position: static;
          z-index: 1;

          .chechBoxItem {
            padding-left: 16px;
          }
        }

        .table_outerItem {
          margin-bottom: 23px;

          @media only screen and (max-width: 767.98px) {
            align-items: flex-start !important;
          }

          .mobile_itemTitle {
            margin-bottom: 0 !important;
            font-size: 12px;
            color: #bbc1c8;
          }

          &:nth-last-of-type(2) {
            .table_innnerItem {
              @media all and (max-width: 767.9px) {
                border: 0.5px solid #71727d;
              }
            }
          }

          .table_innnerItem {
            .innerText {
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;
              color: #43425d;
              margin: 0;
            }

            .innerCounter {
              justify-content: flex-end;

              button {
                border: none;
                background-color: transparent;
                padding: 0;
                outline: none;
                font-size: 20px;
                line-height: 27px;
                font-weight: 600;
              }

              span {
                display: block;
                padding: 0 4px;
                font-size: 14px;
                line-height: 19px;
                font-weight: 600;
              }
            }

            input {
              width: 100%;
              padding-left: 10px !important;
              font-size: 14px;
              line-height: 19px;
              font-weight: 400;
              color: #a6a6ab;
            }

            input:not([type="radio"]) {
              height: 30px !important;
              border: none !important;
              color: $black !important;

              &:disabled {
                border: none !important;
                color: #a6a6ab !important;
              }
            }
          }

          .minusClass {
            color: #f3373d;
          }

          .plusClass {
            color: #397ae8;
          }
        }

        .table_innnerItemNoInput {
          [class*="-control"] {
            min-height: 30px;
          }

          [class*="ValueContainer"] {
            height: 30px !important;
            font-size: 12px;

            img {
              display: none;
            }
          }
        }
      }
    }
  }
  .productTypeSelectImage {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 8px;
    &:hover + .hoveredImage {
      display: block;
    }
  }
  .hoveredImage {
    top: 50px;
    display: none;
    position: absolute;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    z-index: 1;
  }
}

// TODO RETURN MOBILE CONTAINER END

div[data-name*="SaleModals"] {
  .modal-dialog {
    max-width: 520px !important;
    display: flex;
    align-items: center;
    height: calc(100vh - 3.5rem);

    .modal-header {
      display: none;
    }
  }
}

.notifIconParent {
  @media all and (max-width: 767.98px) {
    padding: 0 10px 0 0;
    height: 19px;
    display: flex;
    align-items: center;

    svg {
      height: 19px;

      path {
        fill: #fff;
      }
    }
  }
}

.menuButton {
  height: 16px;
  display: flex;
  align-items: center;
}

// !! COMMENT SECTION START
.comments_container {
  // TODO change height, with opened workers, to fixed: JS calculate
  height: 520px;
  max-height: 520px;
  overflow: auto;

  @media only screen and (max-width: 767.98px) {
    height: 100%;
    max-height: 320px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbc1c8;
    border-radius: 6px;
  }

  margin-top: 45px;

  .comments_item_container {
    margin-bottom: 14px;
    padding: 10px 15px;
    border-radius: 3px;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      box-sizing: 0 3px 6px rgba(#000, 0.16);
    }

    .replyContainer {
      margin-top: 15px;
      border-radius: 3px;
      margin-left: -15px;
      padding-left: 15px;

      &.showReplys {
        border-left: 1px solid #dce0e5;

        .replyCountContent {
          &::after {
            content: "" !important;
          }
        }
      }

      .replyCountContent {
        line-height: 0;
        position: relative;

        &::after {
          content: none;
          position: absolute;
          top: 0;
          width: 8px;
          height: 1px;
          left: -15px;
          background-color: #dce0e5;
        }

        div {
          line-height: 0;
        }
      }
    }

    &.replyComment {
      padding-left: 0;
      padding-right: 0;

      &:hover {
        background-color: #fff;
        box-sizing: 0 3px 6px rgba(#000, 0.16);
      }

      .replyCountContent {
        &::after {
          content: none;
        }
      }
    }

    .commment_item_row {
      display: flex;
      flex-wrap: wrap;

      .comment_item_image {
        flex: 0 0 40px;
        max-width: 40px;
      }

      .comment_item_text {
        flex: 1;
        padding-left: 20px;
      }

      .commentor_image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          margin: 0;
          object-fit: cover;
        }
      }

      .commentor_person {
        display: flex;
        align-items: center;

        .commentor_name {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
        }

        .commentor_date {
          margin: 0;
          padding-left: 15px;
          margin-left: auto;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          .comment_copy {
            cursor: pointer;
            &.copied {
              svg {
                path {
                  fill: #397ae8;
                }
              }
            }
          }
        }
      }
    }

    .comments_text {
      margin-top: 9px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0;

      span {
        margin-right: 4px;
        cursor: pointer;
        color: #397ae8;
        text-decoration: underline;

        &:last-child {
          margin-right: 0;
          margin-left: 4px;
        }
      }
    }

    .replyBtn {
      display: block;
      padding-bottom: 10px;
      cursor: pointer;
      color: #397ae8;
      text-decoration: underline;
    }

    .replyComments {
      font-size: 14px;
      color: #397ae8;
    }
  }
}

// !! COMMENT SECTION END
div[data-name*="SaleModals"] {
  .modal-dialog {
    max-width: 520px !important;
    display: flex;
    align-items: center;
    height: calc(100vh - 3.5rem);

    .modal-header {
      display: none;
    }
  }
}

// SALE MODALS
div {
  &[data-name*="SaleModals"] {
    .modal-dialog {
      max-width: 340px !important;
    }

    .modal-content {
      overflow: hidden;
    }

    .modal-body {
      padding: 0;
    }
  }
}

div[data-name="confirmation"],
div[data-name="rejectModal"],
div[data-name="doneModal"],
div[data-name="alert"] {
  .modal-dialog {
    max-width: 433px;

    .modal-content {
      border-radius: 12px;

      .modal-header {
        padding: 0;

        .close {
          padding: 14px 14px;
          font-size: 0;
          margin: 0 0 0 auto;
          opacity: 1;

          span {
            width: 11px;
            height: 11px;
            font-weight: 700;
          }
        }
      }

      .modal-body {
        padding: 0 52px 22px 41px;

        h2 {
          font-size: 20px;
          margin-bottom: 16px;
          color: #43425d;
          line-height: 22px;
          font-weight: 600;
        }

        .confirmModalDescription {
          margin: 0;
          font-size: 14px;
        }

        .modal-confimration-btns {
          margin-top: 46px;

          button {
            padding: 9px 23px;
            border-radius: 6px;
            border: none;
            font-size: 14px;

            &.submit {
              background-color: #397ae8 !important;
              border: 1px solid #397ae8 !important;
            }

            &:first-child {
              background-color: transparent;
              color: #71727d;
              padding-right: 0;
              font-weight: 400;
              margin-right: 7px;
              border: 1px solid #a6a6ab;
              padding: 9px 22px;
            }

            &:last-child {
              background-color: #f3373d;
              padding: 9px 23px;
              border-radius: 6px;
              color: #fff;
              font-weight: 600;
              border: 1px solid #f3373d;
            }
          }
        }
      }
    }
  }
}

div[data-name="doneModal"] {
  .modal-dialog {
    max-width: 395px !important;

    .modal-content {
      .modal-body {
        padding: 0 25px 28px;
        .modal-confimration-btns {
          margin-top: 46px;

          button {
            &.submit {
              background-color: #397ae8 !important;
              border: 1px solid #397ae8 !important;
            }
            &:first-child {
              border: 0;
              color: #a6a6ab;
            }
            &:last-child {
              background-color: #397ae8;
              padding: 9px 23px;
              border-radius: 6px;
              color: #fff;
              font-weight: 600;
              border: 1px solid #397ae8;
            }
          }
        }
      }
    }
  }
}

[data-name="createEditStickers"] {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 0;

        .close {
          padding: 14px 16px 0 0;
          font-size: 0;
          margin: 0 0 0 auto;

          span {
            width: 11px;
            height: 11px;
            font-weight: 700;
          }
        }
      }

      .modal-body {
        padding: 0 43px 35px 20px;

        @media only screen and (max-width: 768px) {
          padding: 0 15px 10px 15px;
        }

        .stickers-created {
          margin-top: 45px;

          @media only screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }

        .stickersTitle {
          margin-top: -10px;
          font-weight: 400;
          padding-left: 19px;
        }
      }
    }
  }
}

div {
  [data-name="filterFromProducts"] {
    .modal-dialog {
      max-width: 888px !important;
      @media all and (max-width: 767.98px) {
        max-width: 100% !important;
      }

      .modal-content {
        border-radius: 3px;

        .modal-header {
          border-radius: 3px 3px 0 0;
          padding: 14px 12px 14px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f2f4f9;
          height: 40px;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
          }

          .close {
            span {
              display: flex;
              line-height: 12px;
              font-size: 36px;
            }
          }
        }

        .modal-body {
          padding: 0;
        }
      }
    }
  }
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.pickerContainer {
  input {
    width: 280px;
    padding-left: 60px !important;
  }
  @media only screen and (max-width: 767.98px) {
    width: 100%;

    input {
      width: 100%;
    }
  }
  .react-datepicker__input-container {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 14px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(0.451 0.45)'%3E%3Cg transform='translate(-0.451 -0.45)'%3E%3Crect width='24' height='24' transform='translate(0)' fill='none'/%3E%3C/g%3E%3Cg transform='translate(2.565 1.678)'%3E%3Cpath d='M18.973,3.974h-1V2h-2V3.974H7.992V2h-2V3.974H5A1.976,1.976,0,0,0,3.01,5.949L3,19.769a1.985,1.985,0,0,0,2,1.974H18.973a1.991,1.991,0,0,0,2-1.974V5.949A1.991,1.991,0,0,0,18.973,3.974Zm0,15.8H5V9.9H18.973Zm0-11.846H5V5.949H18.973ZM8.99,13.846h-2V11.872h2Zm3.993,0h-2V11.872h2Zm3.993,0h-2V11.872h2ZM8.99,17.8h-2V15.821h2Zm3.993,0h-2V15.821h2Zm3.993,0h-2V15.821h2Z' transform='translate(-3 -2)' fill='%23364364'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      width: 24px;
      height: 24px;
      pointer-events: none;
    }
  }
}

.datesContainer {
  @media only screen and (max-width: 767.98px) {
    width: 100%;
    margin-top: 16px;
  }

  position: relative;

  .timerError {
    position: absolute;
    left: 0;

    p {
      padding-top: 4px;
      font-size: 14px;
      color: #ff0000;
      white-space: nowrap;
    }
  }
}

// !Personal INfo
.CreateEditContentp-37 {
  [class*="-ValueContainer"] {
    flex-wrap: nowrap;

    [class*="-multiValue"] {
      flex: 1 0 auto;
      white-space: nowrap;
      justify-content: space-between;
      display: none;

      &:first-of-type {
        display: flex;
      }
    }
  }
}

// !Personal INfo end
.datepciker-container {
  .text-red {
    position: absolute;

    @media only screen and (max-width: 767.98px) {
      margin-bottom: 5px;
      position: relative;
    }
  }
}

.worker-add-checkbox {
  input {
    width: 30px !important;
    height: 30px !important;
  }
}

// !color picker new style
.lastPicker {
  display: flex;
  align-items: center;

  > div {
    min-width: 25px;
    min-height: 25px;
  }
}

// !color picker new style

[data-name="identificationModal"] {
  .modal-dialog {
    max-width: 1020px !important;
    .modal-body {
      padding: 0;
      margin-top: -53px;
    }
  }
  &.visible {
    z-index: 1051 !important;
  }
}

.showChangeReturnMore {
  .tableContent {
    .table_innnerItem,
    .table_innnerItem * {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }
  }
}

.return-status-button {
  border-radius: 6px;
  background-color: #bf3f3f;
  color: #fff;
  border: none;
  padding: 15px 10px;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0 auto;
  min-width: 243px;
}
.order-status {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  button {
    background-color: #397ae8;
    color: #fff;
    padding: 6px 34px;
    border: none;
    border-radius: 6px;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    outline: none;
  }
  & > div {
    justify-content: flex-start;
    gap: 25px;
    min-width: 200px;
  }
  p {
    text-align: left;
    display: inline-block;
  }
}
.status_red_text {
  display: inline-block;
  max-width: 210px;
  width: 100%;
}
.reports-bg-color {
  .main-page {
    overflow: initial;
  }
}
.home-bg-color {
  .main-page {
    .layoutRightbar {
      // width: calc(100% - 270px) !important;
    }
  }
}

.no-data-tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  font-family: "Noto Sans Armenian";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 300px;
  color: #212529;
}

div {
  &[data-name="checkProduct"] {
    .modal-header {
      > span {
        font-family: "Roboto", sans-serif !important;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}
div {
  &[data-name="importProduct"] {
    .modal-header {
      > span {
        font-family: "Roboto", sans-serif !important;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}
div {
  &[data-name="ProductStatusModal"] {
    .modal-header {
      padding: 20px 20px 30px 30px;
      border-radius: 12px 12px 0px 0px;
      font-family: "Noto Sans Armenian";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #252733;
      border-bottom: 1px solid #e6e9ee;
      > span {
        margin-top: 23px;
        font-family: "Roboto", sans-serif !important;
      }
    }
  }
}

div {
  &[data-name="comments"] {
    &.visible {
      z-index: 1051;
    }
    .modal-header {
      background: #f0f2f5;
      border-radius: 12px 12px 0px 0px;
      font-family: "Noto Sans Armenian";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #252733;
      padding-left: 46px;
      > span {
        font-family: "Roboto", sans-serif !important;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}
div {
  &[data-name="exportProduct"] {
    .modal-header {
      background: #f0f2f5;
      border-radius: 12px 12px 0px 0px;
      font-family: "Noto Sans Armenian";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #252733;
      padding-left: 46px;
      > span {
        font-family: "Roboto", sans-serif !important;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}
div {
  &[data-name="createPreset"] {
    .modal-header {
      border-radius: 12px 12px 0px 0px;
      color: #252733;
      padding-left: 46px;
      > span {
        font-family: "Roboto", sans-serif !important;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}

.negativeValue {
  color: red;
}
