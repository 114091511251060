.mobileMenu {
  z-index: 20;
  position: fixed;
  background-color: #EAEDF2;
  top: 0;
  width: 100%;
  left: 0;
  height: calc(100vh - 80px);
  ul {
    margin-top: 18px;
    list-style: none;
    padding: 0 10px;
    li {
      background-color: #fff;
      width: 100%;
      margin-bottom: 4px;
      padding: 15px 14px;
      border-radius: 3px;
      
      a{
        display: block;
        width: 100%;
        height: 100%;
        color: #43425D;
        &:hover {
          text-decoration: none;
          color: #43425D;
        }
      }
    }
  }
}
@media all and (min-width: 769px) {
  .mobileMenu {
      display: none;
  }
}