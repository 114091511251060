.customersSection {
  .customersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f0f2f5;
    margin-bottom: 35px;
    h2 {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #252733;
      padding: 25px;
      cursor: pointer;
      margin-bottom: 0;
      background-color: #f0f2f5;
    }
  }

  .customersFilterBlock {
    .filterSelectors {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .filterSelectorsLeft {
        display: inline-flex;
        align-items: center;
        gap: 7px;
        .toggleFilterMenu {
          display: flex;
          padding: 12px 18px;
          height: 40px;
          background-color: #397ae8;
          font-size: 14px;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          color: #fff;
          gap: 12px;
          border: 0;
          border-radius: 6px;
          outline: none;
        }
        .searchInputBlock {
          position: relative;
          width: 417px;
          max-width: 100%;
          input {
            height: 40px;
            background-color: #fff;
            padding: 10px 27px 10px 12px;
            border-radius: 6px;
            border: 0;
            width: 100%;
            outline: none;
            border: 1px solid #c1cada;
          }
          .searchButton {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            
            button {
              height: 40px;
              outline: none;
              padding-right: 11px;
              border: 0;
              background-color: transparent;
            }
          }
        }
        .clearButton {
          button {
            height: 40px;
            width: 40px;
            outline: none;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            border-radius: 3px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          }
        }
      }
      .filterSelectorsRight {
        display: flex;
        align-items: center;
        gap: 21px;
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
          color: #43425d;
        }
        button {
          width: 40px;
          height: 40px;
          border-radius: 3px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          background-color: #fff;
          border: 0;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          svg {
            height: 19px;
            path {
              fill: #000;
            }
          }
        }
        .disabledDownload {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
  .allPagination {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    margin-bottom: 85px;
    margin-top: 75px;
    justify-content: flex-end;
    [class*="pagination"] {
      margin-bottom: 0;
      [class*="page-item"] {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          [class*="page-link"] {
            font-size: 18px;
          }
        }
        [class*="page-link"] {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &[class*="active"] {
          [class*="page-link"] {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &[class*="disabled"] {
          opacity: 0.5;
        }
      }
    }
    .ProductsSeries {
      margin-left: 40px;
      display: flex;
      .ProductsSeriesItem {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 16px;
          margin-right: 10px;
        }
        .ProductsSeriesItemSelect {
          width: 70px;
          [class*="indicatorContainer"] {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .filterOptions {
    display: flex;
    margin-top: 20px;
    width: 100%;
    padding: 14px 16px;
    background-color: #fff;
    margin-bottom: 10px;
    align-items: flex-end;
    .rowItem {
      flex: 1 1;
      padding-left: 6px;
      padding-right: 6px;
      &.inputsWrapper {
        flex: 0;
      }
      p {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #252733;
        margin-bottom: 5px;
      }
      [class*="ValueContainer"] {
        overflow: initial;
        * {
          font-size: 12px;
        }
      }
      &:last-child {
        flex: 0 1;
      }
      .ordersCount {
        display: flex;
        align-items: center;
        span {
          margin: 0 5px;
        }
        input {
          width: 80px;
          height: 40px;
          border: 1px solid #a6a6ab;
          border-radius: 6px;
          padding: 8px;
          outline: none;
          font-size: 12px;
          &:focus {
            border: 2px solid #397ae8;
          }
        }
      }
      .searchButton {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          background-color: transparent;
          border-radius: 3px;
          height: 40px;
          white-space: nowrap;
          font-size: 12px;
          outline: none;
          border: 0;
          span {
            padding-right: 8px;
          }
        }
      }
    }
    .datePickerParent {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        z-index: 1;
      }
      button {
        outline: none;
      }
      [class*="react-datepicker__navigation"] {
        top: 8px;
      }
      [class*="react-datepicker__close-icon"] {
        &::after {
          content: none;
        }
      }
      [class*="react-datepicker__navigation--previous"] {
        right: 30px;
        left: auto;
      }
      [class*="react-datepicker__header"] {
        background-color: #fff;
        border-bottom: 0;
      }
      [class*="react-datepicker__current-month"],
      [class*="react-datepicker-time__header"],
      [class*="react-datepicker-year-header"] {
        margin-top: 8px;
        text-align: left;
        padding-left: 14px;
      }
      [class*="react-datepicker__day-names"] {
        margin-top: 8px;
        background-color: rgba(209, 209, 209, 0.53);
      }

      [class*="react-datepicker__day--in-range"] {
        background-color: rgba(#4f46e5, 0.5);
      }
      [class*="react-datepicker__day--keyboard-selected"] {
        background-color: #4f46e5;
      }
    }
    .datePicker input {
      width: 100%;
      border: 2px solid #397ae8;
      border-radius: 6px;
      height: 40px;
      padding: 13px 20px;
      box-shadow: none;
      color: #397ae8;
      font-size: 12px;
    }
    .reportSelects {
      [class*="MenuList"] {
        font-size: 13px !important;
      }
      [class*="-control"] {
        height: 40px;
        border-radius: 6px !important;
      }
    }
    [class*="multiSelect"] {
      [class*="-control"] {
        height: 40px;
        border-radius: 6px !important;
      }
    }
  }
  .ProductsTable {
    .producstTableRoW {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      .productCol {
        padding-right: 15px;
        padding-left: 15px;
        position: relative;
        &.customerNameImage {
          flex: 1 0 380px;
          display: flex;
          @media all and (max-width: 1700px) {
            flex: 1 0 260px;
          }

          .salaryContainer {
            width: 100%;
          }
          input {
            margin-right: 10px;
          }
        }
        &.customerSex {
          flex: 0 0 110px;
          max-width: 110px;
          @media all and (max-width: 1700px) {
            flex: 0 0 100px;
            max-width: 100px;
          }
          @media all and (max-width: 1536px) {
            flex: 0 0 80px;
            max-width: 80px;
          }
        }
        &.customerStatus {
          flex: 0 0 140px;
          max-width: 140px;
          @media all and (max-width: 1700px) {
            flex: 0 0 120px;
            max-width: 120px;
          }

          .statusRateGold {
            width: 71px;
            height: 30px;
            background: #fef5ab;
            border-radius: 4px;
            border: 1px solid #f9c847;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
          .statusRateBronze {
            width: 71px;
            height: 30px;
            background: #f6e4d3;
            border-radius: 4px;
            border: 1px solid #e2c8b0;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
          .statusRateSilver {
            width: 71px;
            height: 30px;
            background: #d5ecf2;
            border-radius: 4px;
            border: 1px solid #a5c8d1;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
        }
        &.customerRate {
          flex: 0 0 160px;
          max-width: 160px;
          cursor: pointer;
          position: relative;
          @media all and (max-width: 1700px) {
            flex: 0 0 120px;
            max-width: 120px;
          }
          .productRate {
            display: flex;
            align-items: center;
            gap: 12px;
            position: relative;
            .ratesCount {
              color: #467fdf;
              text-decoration: underline;
            }
            .tableRateDropdown {
              z-index: 99;
              min-width: 220px;
              background: #fff;
              position: absolute;
              top: 30px;
              left: 40px;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
              border-radius: 6px;
              overflow: hidden;
              > span {
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                &:hover {
                  background-color: #f2f3f7;
                }
                span {
                  cursor: pointer;
                }
              }
            }
          }
          &:hover {
            .rateDropdown {
              display: block;
            }
          }
        }
        &.customerDate {
          flex: 0 0 140px;
          max-width: 140px;
          @media all and (max-width: 1700px) {
            flex: 0 0 140px;
            max-width: 140px;
          }
        }
        &.customerCount {
          flex: 0 0 170px;
          max-width: 170px;
          @media all and (max-width: 1700px) {
            flex: 0 0 120px;
            max-width: 120px;
          }
        }
        &.customerSpend {
          flex: 0 0 180px;
          max-width: 180px;
          @media all and (max-width: 1700px) {
            flex: 0 0 180px;
            max-width: 180px;
          }
          @media all and (max-width: 1536px) {
            flex: 0 0 150px;
            max-width: 150px;
          }
        }
        &.customerMore {
          flex: 0 0 180px;
          max-width: 180px;
          @media all and (max-width: 1700px) {
            flex: 0 0 120px;
            max-width: 120px;
          }
          @media all and (max-width: 1536px) {
            flex: 0 0 80px;
            max-width: 80px;
          }
        }
        &.customerDelete {
          flex: 0 0 60px;
          max-width: 60px;
          @media all and (max-width: 1700px) {
            flex: 0 0 40px;
            max-width: 40px;
          }
        }
        .textItem {
          p {
            display: block;
            align-items: center;
            width: 100%;
            &.copied {
              color: #379fff;
              svg {
                path {
                  fill: #379fff;
                }
              }
            }
            button {
              position: absolute;
              padding: 0;
              top: -7px;
              right: 0;
              margin: 0;
              background-color: transparent;
              outline: none;
              display: inline-block;
              border: 0;
              visibility: hidden;
              opacity: 0;
            }
          }
        }
        &:hover {
          .textItem {
            button {
              visibility: visible !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    .productsTableHeader {
      background-color: #f0f2f5;
      padding: 11px 13px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 3px;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        img {
          margin-left: 7px;
        }
      }
    }
    .productsTableItem {
      margin-bottom: 4px;
      padding: 11px 13px;
      background-color: #fff;
      &:nth-child(even) {
        background: rgba(240, 242, 245, 0.8);
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 0;
      }

      .swichItem {
        button {
          text-decoration: underline;
          border: 0;
          background-color: transparent;
          outline: none;
          padding: 0;
          color: #467fdf;
        }
      }
      .productsActions {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          border: 0;
          outline: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          background-color: transparent;
          transition: 0.3s;
          &:hover {
            background-color: rgba(#dce0e5, 0.5);
          }
        }
      }
      .customerCount {
        p {
          text-align: right;
          padding-right: 43px;
        }
      }
    }
  }
  .nameCheckbox {
    display: flex;
    align-items: center;
    width: 100%;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    label {
      margin: 0;
      cursor: pointer;
      input {
        display: none;
        &:checked {
          + span {
            &::after {
              content: "";
            }
          }
        }
      }
      span {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        position: relative;
        margin-right: 20px;
        border-radius: 3px;
        padding: 2px;
        border: 2px solid #397ae8;
        &::after {
          content: none;
          width: 12px;
          height: 12px;
          border-radius: 3px;
          position: absolute;
          left: 50%;
          top: 50%;
          background-color: #397ae8;
          transform: translate(-50%, -50%);
        }
      }
    }
    &.partialChecked {
      label {
        margin: 0;
        cursor: pointer;
        input {
          display: none;
          &:checked {
            + span {
              &::after {
                content: "";
              }
            }
          }
        }
        span {
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          position: relative;
          margin-right: 20px;
          border-radius: 3px;
          padding: 2px;
          border: 2px solid #397ae8;
          &::after {
            content: "";
            width: 12px;
            height: 2px;
            border-radius: 3px;
            position: absolute;
            left: 50%;
            top: 50%;
            background-color: #397ae8;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .moreContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: -13px;
    margin-right: -13px;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
    width: calc(100% + 26px);
    .moreTableHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 55px;
      .moreTableHeaderLeft {
        display: flex;
        align-items: center;
      }
      .moreTableHeaderRight {
        display: flex;
        align-items: center;
        gap: 80px;
        .addressTable,
        .numberTable {
          position: relative;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .addressDropdown,
          .numberDropdown {
            display: none;
            height: fit-content;
            padding: 10px;
            background: #fff;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            position: absolute;
            top: 20px;
            left: 20px;
            border-radius: 6px;
            p {
              border-bottom: 1px solid #eaedf2;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
          }
        }
        .addressTable:hover {
          .addressDropdown {
            display: block;
          }
        }
        .numberTable:hover {
          .numberDropdown {
            display: block;
          }
        }
      }
      p {
        margin: auto;
      }
    }
    > .loadMore {
      background-color: transparent;
      font-weight: 600;
      line-height: 21px;
      margin-top: 15px;
      border: 0;
      outline: none;
      text-decoration: underline;
    }
  }
  .empty {
    width: 50%;
    height: 2px;
    background: #43425d;
  }
}

.closeIcon {
  cursor: pointer;
}
.searchInputWrapper {
  width: 100%;
  position: relative;
  input {
    width: 100%;
  }
  .iconWrapper {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    height: 100%;
    width: 35px;
    right: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    span {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      &.resetIcon {
        svg {
          path {
            stroke: #98a2b2;
          }
        }
      }
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 35px;
    top: 50% !important;
    transform: translateY(-50%) !important;
    height: calc(100% - 15px);
    width: 1px;
    background-color: #c1cada;
  }
}
