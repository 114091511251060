.table {
  margin-top: 10px;
  .tableWrapper {
    overflow: auto hidden;
    padding-bottom: 170px;
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        tr {
          th {
            padding: 6px 7px 6px 6px;
            background: #f0f2f5;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #7e8295;
            &:last-child {
              position: sticky;
              z-index: 3;
              right: 0;
              &::after {
                background-color: #f0f2f5;
                position: absolute;
                top: 0;
                bottom: -1px;
                left: 0;
                width: 8px;
                transform: translateX(-100%);
                transition: box-shadow 0.3s;
                content: "";
                pointer-events: none;
                box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
              }
            }
          }
        }
      }
    }
  }
}

.allPagination {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  margin-top: 36px;
  justify-content: flex-end;
  :global {
    .pagination {
      margin-bottom: 0;
      .page-item {
        &:not(:last-child) {
          margin-right: 7px;
        }
        &:first-child,
        &:last-child {
          .page-link {
            font-size: 18px;
          }
        }
        .page-link {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #e8e9ec;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43425d;
          font-size: 10px;
        }
        &.active {
          .page-link {
            color: #fff;
            border-color: #397ae8;
            background-color: #397ae8;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .ProductsSeries {
    margin-left: 40px;
    display: flex;
    .ProductsSeriesItem {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 16px;
        margin-right: 10px;
      }
      .ProductsSeriesItemSelect {
        width: 70px;
        [class*="indicatorContainer"] {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.inputs {
  display: flex;
  gap: 10px;
  row-gap: 20px;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .selectFilter {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    .title {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      margin-bottom: 5px;
    }
  }
  .ProductsSearch {
    display: flex;
    justify-content: flex-end;
    position: relative;
    button {
      position: absolute;
      right: 0;
      margin: 0;
      border: none;
      height: 40px;
      padding: 0 11px;
      background-color: transparent;
      outline: none;
    }
  }
  .searchFilter {
    position: relative;
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    padding-top: 25px;
    input {
      box-sizing: border-box;
      outline: none;
      padding: 10px 30px 10px 10px;
      width: 100%;
      height: 40px;
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      border: 1px solid #c1cada;
      border-radius: 6px;
      &::placeholder {
        color: #98a2b2;
      }
    }
  }
  [class*="-control"] {
    border-radius: 6px !important;
    height: 40px;
    border-color: #c1cada !important;
  }
  [class*="-ValueContainer"] {
    overflow: initial;
  }
  [class*="-Input"] {
    position: static;
  }
}
.icons {
  display: flex;
  span.searchIcon {
    border-left: 1px solid #c1cada;
    padding-left: 3px;
  }
}
