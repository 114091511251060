.pageContainer {
    margin: 0;
    .ProductBuyingHeader {
        .statesHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #f0f2f5;
            margin-bottom: 35px;
            h2 {
                font-family: "Roboto", sans-serif !important;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #252733;
                padding: 25px;
                cursor: pointer;
                background-color: #f0f2f5;
            }
            button {
                background-color: #397ae8;
                color: #fff;
                padding: 9px 20px;
                border: none;
                border-radius: 3px;
                font-family: inherit;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
            }
        }
    }
}