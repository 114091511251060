.columnInnerItem {
  width: 100%;
  .innerItem {
    margin: 0;
    font-size: 14px;
    width: 100%;
    color: #43425d;
    padding: 0 40px 0 5px;
  }
  .innerItemTitle {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #dce0e5;
    @media all and (max-width: 767.98px) {
      svg {
        margin-left: 15px;
        margin-right: auto;
      }
    }
  }
  .innerItemAddress {
    .bold {
      font-weight: bold;
    }
    a {
      padding-left: 10px;
      color: #397ae8;
      font-size: 12px;
      text-decoration: none;
      display: flex;
      align-items: center;
      span {
        &:first-child {
          @media all and (max-width: 767.98px) {
            display: none;
          }
        }
        &:last-child {
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          background-color: rgba(#397ae8, 0.15);
          border-radius: 50%;
        }
        svg {
          height: 11px;
        }
      }
    }
  }
  .innerItemDate,
  .innerItemQuantity,
  .innerItemTotal,
  .innerItemPostINdex {
    margin: 10px 0;
    span {
      display: block;
      font-size: 14px;
      color: #43425d;
      font-weight: 600;
      &:first-child {
        font-size: 10px;
        font-weight: 400;
        color: #bbc1c8;
      }
    }
  }
  .innerItemPostINdex {
    span {
      &:last-child {
        text-transform: uppercase;
      }
    }
  }
  .innerItemStickers {
    margin-top: 10px;
    margin-bottom: 16px;
    p {
      margin: 0 0 8px;
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .innerItemComment {
    display: flex;
    justify-content: space-between;
    color: #397ae8;
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    span {
      svg {
        margin-right: 5px;
      }
      color: #397ae8;
      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }
  .innerItemExpand {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    span {
      display: flex;
      width: 40px;
      justify-content: center;
      cursor: pointer;
    }
    svg {
      width: 13px;
      transform: rotate(90deg);
    }
    &.expanded {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  .cardDeliveryInfo {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    .innerItem {
      flex: 0 0 50%;
      max-width: 50%;
      &.innerItemPostINdex {
        span {
          display: block;
        }
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    @media all and (max-width: 767.98px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .openedInnerItems {
    display: flex;
    align-items: flex-start;
    margin: 0 -11px;
    .innerItem {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 16px;
    }
  }
}
.mainSearchInnerItem {
  position: relative;
  .searchItemName {
    font-size: 14px;
    color: #43425d;
  }
  .innerItemStickers {
    padding-right: 20px;
    margin-bottom: 0;
  }
  .seachCardItemImage {
    position: absolute;
    bottom: -5px;
    right: -5px;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      max-width: 100%;
    }
  }
}
.search {
  color: black;
  font-size: 12px;
}
