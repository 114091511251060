.total_price{
    text-align: center;
}
:root {
    --grid: 8px;
    --pink: #fd3afd;
    --pinkDark: #690169;
    --greyLight: #515b7d;
    --black: #cdcdce;
    --borderWidth: 4px;
    --borderRadius: 8px;
}

body {
    background: var(--black);
    user-select: none;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}
.app {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.columns {
    display: flex;
}



.column {
    margin: calc(var(--grid) / 2);
    border: var(--borderWidth) solid #0ff0fc;
    border-radius: var(--borderRadius);
    background-color: #2a2f41;
}

.column-title {
    font-size: 40px;
    color: var(--pink);
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.5);
    text-align: center;
    margin: 0;
}

.column-title:hover {
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.8);
}

.task-list {
    /* this has no visible impact, but is in line with the absolute position spacing.
    doing this ensures that dropping into an empty list will be in the correct visual position */
    padding-left: var(--grid);
    padding-right: var(--grid);
    scrollbar-color: dark;
}

.task-list::-webkit-scrollbar {
    width: 1em;
}

.task-list::-webkit-scrollbar-track {
    background-color: var(--greyLight);
    borderradius: var(--borderRadius);
}

.task-list::-webkit-scrollbar-thumb {
    background-color: var(--pink);
}

.item {
    background: #333851;
    border: var(--borderWidth) solid mediumpurple;
    box-sizing: border-box;
    border-radius: var(--borderRadius);
    color: #cdd5ee;
    font-size: 30px;
    user-select: none;

    transition: 0.1s !important;
    /* center align text */
    display: flex;
    justify-content: center;
    align-items: center;
}

.item.is-dragging {
    background: #515b7d;
    border-color: #08ff08;
    box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px MediumSeaGreen;
}
