.header {
    .headerRow {
        display: flex;
        border-bottom: 2px solid #f0f2f5;
        .tab {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #252733;
            padding: 25px;
            cursor: pointer;
            &.active {
                background: #f0f2f5;
            }
        }
    }
}
.modalTabs {
    .headerRow {
        display: flex;
        border-bottom: 2px solid #f0f2f5;
        gap: 34px;
        margin-bottom: 50px;
        .tab {
            font-family: "Roboto", sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #252733;
            padding: 0 0 11px;
            cursor: pointer;
            position: relative;
            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #379fff;
            }
            &.active {
                color: #379fff;
                &::after {
                    content: "";
                }
            }
        }
    }
}
.tableTabs {
    .pageHeader {
        background: #f0f2f5;
        padding: 0 10px;
        margin-bottom: 30px;
    }
    .headerRow {
        border-bottom: 0;
        .tab {
            padding: 5px 16px;
            &.active {
                background: #fff;
                color: #1d9ef9;
            }
        }
    }
}
